/* ========================================================================
 * Apricot's Form Modules
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/form.scss';
import '../scss/includes/button.scss';

// javaScript
import Utils from './CBUtils';

// ------------------------------------  SELECT

/**
 * Custom form select
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {Element} data.parentElm
 * @param {Boolean} data.truncate
 * @param {Boolean} data.hiddenParent
 * @returns {{change: Function}}
 * @returns {{disable: Function}}
 * @returns {{destroy: Function}}
 */
const customSelectElement = (data = {}) => {
  const defaultData = {
    elem: null,
    markup: true,
    parentElm: null,
    truncate: true,
    hiddenParent: false,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let truncate = data.truncate;
  let hiddenParent = data.hiddenParent;
  let parentElm = data.parentElm;

  if (!Utils.elemExists(elem)) return false;

  let span1 = null;
  let span3 = null;
  let i = null;

  const toggleEntities = text => {
    let k;
    const tagsList = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
    };

    // generate a regex object based on a list of tags, if the flag set
    // to true, it will generate a regex for the tag, otherwise it
    // will generate a list of entities
    const buildRegex = tagFlag => {
      var items = [];
      if (tagFlag) {
        for (k in tagsList) {
          items.push(k);
        }
      } else {
        for (k in tagsList) {
          items.push(tagsList[k]);
        }
      }

      return new RegExp(items.join('|'), 'g');
    };

    const checkRegex = (pattern, str) => {
      return pattern.test(str);
    };

    const replaceToEntity = tag => {
      return tagsList[tag] || tag;
    };

    const replaceToTag = entity => {
      for (var tag in tagsList) {
        if (tagsList[tag] == entity) return tag;
      }

      return entity;
    };

    // are do we have html entities?
    if (checkRegex(buildRegex(false), text)) {
      // convert entities to tags
      return text.replace(buildRegex(false), replaceToTag);
    } else {
      // no entities, convert tags to entities ...
      return text.replace(buildRegex(true), replaceToEntity);
    }
  };

  const getRealWidth = el => {
    let result = 0;
    let parentIsHidden = false;

    if (!el) return 0;

    if (hiddenParent) {
      if (Utils.elemExists(parentElm)) {
        parentIsHidden = parentElm.style.display === 'none' ? true : false;
        //only if parent is hidden
        if (parentIsHidden) {
          Utils.show(parentElm);

          result = el.offsetWidth;

          Utils.hide(parentElm);
        } else {
          result = el.offsetWidth;
        }
      }
    } else {
      result = el.offsetWidth;
    }

    return result;
  };

  // check for entities in string
  const textCleanup = value => {
    if (/&(?:[a-z]+|#\d+);/.test(value)) {
      let txtArea = document.createElement('TEXTAREA');

      value = txtArea.innerText;

      txtArea = null;
    }

    return value;
  };

  const truncateValue = value => {
    if (!span1) return value;

    // 16 is the padding of the select
    const containerWidth = getRealWidth(span1) - 16;
    if (containerWidth <= 0) return value;

    const buttonWidth = i.offsetWidth;
    let valueWidth = 0;
    let maxWidth = 0;
    let maxChars = 0;
    let tmpTxt = textCleanup(value);
    let tmp = document.createElement('SPAN');

    Utils.addClass(tmp, 'cb-tmp-element');

    document.body.appendChild(tmp);

    tmp.innerHTML = value;

    valueWidth = tmp.offsetWidth;

    maxWidth = parseInt(containerWidth - buttonWidth, 10);

    if (maxWidth <= valueWidth) {
      if (maxWidth > 0) {
        //Calculate maximum number of characters for the string
        while (tmp.offsetWidth > maxWidth) {
          tmpTxt = textCleanup(tmp.innerHTML);

          tmpTxt = tmpTxt.substring(0, tmpTxt.length - 1);

          tmp.innerHTML = tmpTxt;
        }
        maxChars = tmpTxt.length;

        value = Utils.textTruncate(value, maxChars, 'last', '...');
      } else {
        value = '...';
      }
    }

    Utils.remove(tmp);

    return value;
  };

  const update = () => {
    let text = elem.options[elem.selectedIndex] ? elem.options[elem.selectedIndex].text : '';
    let value = toggleEntities(text);
    value = truncate ? truncateValue(value) : value;

    i = document.createElement('I');

    Utils.addClass(i, ['cb-select-arrow', 'cb-icon', 'cb-down']);

    span3.innerHTML = value;

    span3.appendChild(i);
  };

  const disable = () => {
    Utils.addClass(span1, 'disabled');
  };

  const enable = () => {
    Utils.removeClass(span1, 'disabled');
  };

  const addEvents = () => {
    elem.addEventListener('change', update);

    elem.addEventListener('keyup', update);

    elem.addEventListener('keydown', e => {
      if (Utils.whichKey(e) === 'ENTER') {
        e.preventDefault();
      }
    });

    //Update disabled state
    if (elem.disabled || Utils.hasClass(elem, 'disabled')) {
      disable();
    }

    // Change class names to enable styling
    elem.addEventListener('mouseenter', () => {
      Utils.addClass(span1, 'active');
    });

    elem.addEventListener('mouseleave', () => {
      Utils.removeClass(span1, 'mouseover');
    });

    elem.addEventListener('focus', () => {
      Utils.addClass(span1, 'focus');
    });

    elem.addEventListener('mouseover', () => {
      Utils.addClass(span1, 'mouseover');
    });

    elem.addEventListener('blur', () => {
      Utils.removeClass(span1, 'focus');
    });

    //adjust text length based on font-size for current viewport
    window.addEventListener('resize', update);

    //----- Define Custom events
    // can be called when we want to change the value with code
    elem.addEventListener('apricot_valueChanged', update);

    // will add custom disable style to dropDown
    elem.addEventListener('disable', disable);

    // will remove custom disable style from dropDown
    elem.addEventListener('enable', enable);

    const event = new CustomEvent('apricot_valueChanged');

    //Make sure we display the correct value, Update cb-select
    elem.dispatchEvent(event);
  };

  const change = () => {
    // if ('createEvent' in document) {
    // var evt = document.createEvent("HTMLEvents");
    // evt.initEvent("change", false, true);
    // elem.dispatchEvent(evt);
    const event = new Event('change');

    elem.dispatchEvent(event);
    // } else {
    //   elem.fireEvent('onchange');
    // }
  };

  const disableStatus = status => {
    if (status) {
      Utils.attr(elem, 'disabled', 'true');
      const event = new CustomEvent('disable');

      elem.dispatchEvent(event);
    } else {
      Utils.removeAttr(elem, 'disabled');
      const event = new CustomEvent('enable');

      elem.dispatchEvent(event);
    }
  };

  const destroy = () => {
    if (elem.customSelectElement === 'cb') {
      elem.customSelectElement = null;

      elem.removeEventListener('change', update);

      elem.removeEventListener('keyup', update);

      elem.removeEventListener('apricot_valueChanged', update);

      elem.removeEventListener('disable', disable);

      window.removeEventListener('resize', update);

      // Remove markup
      const parent = Utils.parent(elem);
      var span = Utils.getByClass('cb-select', parent)[0];
      const wrap = Utils.getClosest(elem, '.cb-select-container');

      Utils.remove(span);

      Utils.removeClass(elem, 'cb-replaced');

      Utils.unwrap(wrap);
    }
  };

  const init = () => {
    if (elem.getAttribute('multiple')) return false;

    elem.customSelectElement = 'cb';

    if (data.markup) {
      span1 = document.createElement('SPAN');

      span3 = document.createElement('SPAN');

      Utils.addClass(span1, 'cb-select');

      span1.setAttribute('aria-hidden', true);

      const span2 = document.createElement('SPAN');

      span1.appendChild(span2);

      span2.appendChild(span3);

      i = document.createElement('I');

      Utils.addClass(i, ['cb-select-arrow', 'cb-icon', 'cb-down']);

      span3.innerHTML = elem.options[elem.selectedIndex] ? elem.options[elem.selectedIndex].text : '';

      span3.appendChild(i);

      const div = document.createElement('DIV');

      Utils.addClass(div, 'cb-select-container');

      Utils.wrap(elem, div);

      Utils.insertAfter(elem, span1);

      Utils.addClass(elem, 'cb-replaced');
    } else {
      let p = Utils.parent(elem);

      span1 = p.querySelector('.cb-select');

      i = p.querySelector('.cb-select-arrow');

      span3 = Utils.parent(i);

      Utils.addClass(elem, 'cb-replaced');
    }

    addEvents();
  };

  if (elem.customSelectElement !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
    change: change,
    disable: disableStatus,
  };
};

// ------------------------------------  CHECKBOX, RADIO BUTTON

/**
 * Custom checkbox and radio button
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {String} data.type
 * @param {Function} data.onChangeApricot
 * @returns {{destroy: Function}}
 */
const customFormElement = (data = {}) => {
  const defaultData = {
    elem: null,
    type: undefined,
    onChangeApricot: null,
    onKeydownReact: false,
  };

  data = {
    ...defaultData,
    ...data,
  };

  const typeOptions = ['checkbox', 'radio'];
  let elem = data.elem;
  let type = data.type;

  if (!Utils.elemExists(elem)) return false;

  const checkboxEvents = e => {
    // only for none-react components
    if (Utils.whichKey(e) === 'ENTER' && !data.onKeydownReact) {
      e.preventDefault();

      // Revert
      elem.checked = !elem.checked;

      const event = new Event('change');

      elem.dispatchEvent(event);
    }
  };

  const destroy = () => {
    if (elem.customFormElement === 'cb') {
      elem.customFormElement = null;

      elem.removeEventListener('keydown', checkboxEvents);
    }
  };

  const init = () => {
    type = type || elem.getAttribute('type');
    if (!typeOptions.includes(type)) return false;

    if (type === 'checkbox') {
      elem.addEventListener('keydown', checkboxEvents);
    }

    elem.customFormElement = 'cb';
  };

  if (elem.customFormElement !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};
// ------------------------------------  FLOATING LABEL

/**
 * Floating labels for Input and Textarea tags
 *
 * @export
 * @param {Element} elem
 * @returns {{destroy: Function}}
 */
const floatingLabel = (elem = {}) => {
  if (!Utils.elemExists(elem)) return null;

  let cbInput = null;
  let label = null;
  let fieldType = '';

  const onAutoFillStart = event => {
    if (event.animationName === 'on-auto-fill-start-floating') {
      Utils.addClass(label, 'cb-fill-fl');

      const customEvent = new CustomEvent('apricot_inputFill');

      elem.dispatchEvent(customEvent);
    }
  };
  const getValue = () => {
    let value = '';

    switch (fieldType) {
      case 'select':
        value = Array.from(elem.selectedOptions)
          .map(option => option.value)
          .toString();
        break;
      case 'input':
      case 'textarea':
        value = elem.value;
        break;
      case 'button':
        value = elem.querySelector('.cb-select-dropdown-value').innerText;
        break;
    }

    return value;
  };

  const labelChangeEvents = () => {
    const value = getValue();

    elem.setAttribute('data-cb-value', value);

    if (value !== '') {
      Utils.addClass(label, 'cb-value-fl');

      Utils.removeClass(label, 'cb-fill-fl');
    } else {
      Utils.removeClass(label, 'cb-value-fl');
    }
  };

  const labelFocusEvents = () => {
    // for select dropdown, because the trigger is an anchor tag
    if (Utils.hasClass(cbInput, 'cb-disabled')) return;

    Utils.addClass(label, 'cb-focus-fl');

    const customEvent = new CustomEvent('apricot_inputFocus');

    elem.dispatchEvent(customEvent);
  };

  const removeLabelFocusEvents = () => {
    Utils.removeClass(label, 'cb-focus-fl');

    const customEvent = new CustomEvent('apricot_inputBlur');

    elem.dispatchEvent(customEvent);
  };

  const destroy = () => {
    if (elem.floatingLabelPlugin === 'cb') {
      elem.floatingLabelPlugin = null;

      elem.removeEventListener('keyup', labelChangeEvents);

      elem.removeEventListener('input', labelChangeEvents);

      elem.removeEventListener('focus', labelFocusEvents);

      elem.removeEventListener('blur', removeLabelFocusEvents);

      elem.removeEventListener('change', removeLabelFocusEvents);

      elem.removeEventListener('animationstart', onAutoFillStart, false);

      Utils.removeClass(label, 'cb-value-fl');

      Utils.removeClass(label, 'cb-focus-fl');

      Utils.removeClass(label, 'cb-fill-fl');
      const $f = Utils.getClosest(elem, '.cb-floating-label');

      Utils.removeClass($f, 'cb-floating-label');
    }
  };

  const init = () => {
    fieldType = elem.tagName.toLowerCase();

    if (fieldType === 'input' || fieldType === 'textarea') {
      cbInput = Utils.getClosest(elem, '.cb-input');
    } else if (fieldType === 'select') {
      cbInput = Utils.getClosest(elem, '.cb-select');
      if (Utils.browser().msie) {
        const parent = Utils.getClosest(elem, '.cb-floating-label');
        if (Utils.elemExists(parent)) {
          Utils.addClass(parent, 'cb-not-active');
        }

        return false;
      }
    } else if (fieldType === 'button') {
      cbInput = Utils.getClosest(elem, '.cb-select');
    }

    if (Utils.elemExists(cbInput)) {
      label = Utils.getByTag('label', cbInput)[0];
    }

    if (!Utils.elemExists(label)) return null;

    if (elem.disabled === true || Utils.hasClass(elem, 'cb-disabled')) {
      Utils.addClass(cbInput, 'cb-disabled');
    }

    labelChangeEvents();

    if (fieldType === 'input' || fieldType === 'textarea') {
      elem.addEventListener('keyup', labelChangeEvents);

      elem.addEventListener('input', labelChangeEvents);

      elem.addEventListener('change', removeLabelFocusEvents);
    } else if (fieldType === 'select') {
      elem.addEventListener('change', labelChangeEvents);
    } else if (fieldType === 'button') {
      // Get the element you want to observe
      const targetNode = elem.querySelector('.cb-select-dropdown-value');

      if (Utils.elemExists(targetNode)) {
        // Options for the observer (which mutations to observe)
        const config = { childList: true, characterData: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback = function (mutationsList) {
          for (let mutation of mutationsList) {
            if (mutation.type === 'childList' || mutation.type === 'characterData') {
              if (mutation.target.nodeName === 'SPAN') {
                labelChangeEvents();
              }
            }
          }
        };
        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
      }
      cbInput.addEventListener(
        'focus',
        event => {
          if (cbInput.contains(event.target)) {
            labelFocusEvents();
          } else {
            removeLabelFocusEvents();
          }
        },
        true,
      );
    }

    elem.addEventListener('focus', labelFocusEvents);

    elem.addEventListener('blur', removeLabelFocusEvents);

    // autofill
    elem.addEventListener('animationstart', onAutoFillStart, false);

    elem.floatingLabelPlugin = 'cb';
  };

  if (elem.floatingLabelPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

// ------------------------------------  CLEAR INPUT

/**
 * Clear Input for Input tags
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.validate
 * @returns {{destroy: Function}}
 */

const clearInput = (data = {}) => {
  const defaultData = {
    elem: null,
    validate: false,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let icon = null;
  let iconWrapper = null;

  if (!Utils.elemExists(elem)) return null;

  const cbInput = Utils.getClosest(elem, '.cb-clear-input');
  let btn = null;

  if (Utils.elemExists(cbInput)) {
    btn = cbInput.querySelector('.cb-btn');
  }

  if (!Utils.elemExists(btn)) return null;

  const elemFocusEvents = () => {
    Utils.addClass(cbInput, 'cb-focus-elem-ci');
  };

  const removeElemFocusEvents = () => {
    const browser = Utils.browser().name;
    const time = browser === 'Chrome' ? 50 : 200;

    setTimeout(() => {
      Utils.removeClass(cbInput, 'cb-focus-elem-ci');
    }, time);
  };

  const btnFocusEvents = () => {
    Utils.addClass(cbInput, 'cb-focus-btn-ci');
  };
  const removeBtnFocusEvents = () => {
    Utils.removeClass(cbInput, 'cb-focus-btn-ci');
  };
  const btnChangeEvents = () => {
    elem.setAttribute('data-cb-value', Utils.getValue(elem));

    if (Utils.getValue(elem) !== '') {
      Utils.addClass(cbInput, 'cb-value-ci');

      const customEvent1 = new CustomEvent('apricot_hasValue');

      customEvent1.data = Utils.getValue(elem);

      elem.dispatchEvent(customEvent1);
    } else {
      Utils.removeClass(cbInput, 'cb-value-ci');

      const customEvent2 = new CustomEvent('apricot_clearValue');

      elem.dispatchEvent(customEvent2);
    }
  };

  const clearValue = () => {
    elem.value = '';

    btnChangeEvents();

    setTimeout(() => {
      var event1 = new Event('change');
      elem.dispatchEvent(event1);

      var event2 = new Event('keyup');
      elem.dispatchEvent(event2);

      const customEvent = new CustomEvent('apricot_clearValue');

      elem.dispatchEvent(customEvent);

      elem.focus();
    }, 10);
  };

  const destroy = () => {
    if (elem.clearInputPlugin === 'cb') {
      elem.clearInputPlugin = null;

      elem.removeEventListener('keyup', btnChangeEvents);

      elem.removeEventListener('focus', elemFocusEvents);

      elem.removeEventListener('blur', removeBtnFocusEvents);

      if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        elem.removeEventListener('touchend', elemFocusEvents);
      }

      btn.removeEventListener('click', clearValue);

      btn.removeEventListener('focus', elemFocusEvents);

      btn.removeEventListener('blur', removeBtnFocusEvents);

      icon && icon.remove();
      if (data.validate) {
        Utils.removeClass(cbInput, 'cb-validation-success');
      }

      Utils.removeClass(cbInput, 'cb-focus-elem-ci');

      Utils.removeClass(cbInput, 'cb-focus-btn-ci');

      Utils.removeClass(cbInput, 'cb-value-ci');
    }
  };

  const init = () => {
    if (elem.disabled === true) {
      Utils.addClass(cbInput, 'cb-disabled');

      return null;
    }

    if (data.validate) {
      iconWrapper = document.createElement('DIV');

      Utils.addClass(iconWrapper, 'cb-validation-label-input');

      icon = document.createElement('I');

      Utils.addClass(icon, ['cb-validation-icon', 'cb-icon', 'cb-check-fill']);

      Utils.attr(icon, 'aria-hidden', true);

      Utils.addClass(cbInput, ['cb-validation-success', 'cb-validation-success-ci']);

      if (cbInput.querySelector('.cb-input-icon-left') || cbInput.querySelector('.cb-input-icon-right')) {
        const container = cbInput.querySelector('.cb-input-icon-left')
          ? cbInput.querySelector('.cb-input-icon-left')
          : cbInput.querySelector('.cb-input-icon-right');

        Utils.insertAfter(container, icon);
      } else {
        const children = cbInput.children;

        Utils.wrapAll(children, iconWrapper);

        Utils.insertAfter(iconWrapper, icon);
      }
    }

    btnChangeEvents();

    elem.addEventListener('keyup', btnChangeEvents);

    elem.addEventListener('focus', elemFocusEvents);

    elem.addEventListener('blur', removeElemFocusEvents);
    // to improve clearInput experience for mobile devices
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      elem.addEventListener('touchend', event => {
        elemFocusEvents(event);
      });
    }

    btn.addEventListener('click', clearValue);

    btn.addEventListener('focus', btnFocusEvents);

    btn.addEventListener('blur', removeBtnFocusEvents);

    elem.clearInputPlugin = 'cb';
  };

  if (elem.clearInputPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

// ------------------------------------  SWITCH

/**
 * Custom toggle switch checkbox
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {String} data.on
 * @param {String} data.off
 * @returns {{destroy: Function}}
 */
const toggleSwitch = (data = {}) => {
  const defaultData = {
    elem: null,
    on: 'On',
    off: 'Off',
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let onValue = data.on;
  let offValue = data.off;

  if (!Utils.elemExists(elem)) return null;
  let label = null;
  const switchToggle = Utils.getClosest(elem, '.cb-toggle-switch');
  const switchWrap = Utils.getClosest(elem, '.cb-switch');

  if (Utils.elemExists(switchToggle)) {
    label = Utils.getByTag('label', switchToggle)[0];
  }
  if (!Utils.elemExists(label)) return null;
  if (!Utils.elemExists(switchWrap)) return null;

  const changeValue = checked => {
    if (checked) {
      label.innerHTML = onValue;
    } else {
      label.innerHTML = offValue;
    }
  };

  const changeEvent = () => {
    if (elem.checked) {
      Utils.addClass(switchWrap, 'cb-checked');

      Utils.attr(elem, 'aria-checked', 'true');
    } else {
      Utils.removeClass(switchWrap, 'cb-checked');

      Utils.attr(elem, 'aria-checked', 'false');
    }

    changeValue(elem.checked);
  };

  const pressEvent = () => {
    Utils.addClass(switchWrap, 'cb-press');
  };
  const removePressEvent = () => {
    Utils.removeClass(switchWrap, 'cb-press');
  };

  const focusEvent = () => {
    Utils.addClass(switchWrap, 'cb-focus');
  };
  const removeFocusEvent = () => {
    Utils.removeClass(switchWrap, 'cb-focus');
  };

  const destroy = () => {
    if (elem.toggleSwitchPlugin === 'cb') {
      elem.toggleSwitchPlugin = null;

      elem.removeEventListener('change', changeEvent);

      elem.removeEventListener('focus', focusEvent);

      elem.removeEventListener('blur', removeFocusEvent);

      elem.removeEventListener('mousedown', removeFocusEvent);

      elem.removeEventListener('mouseup', removeFocusEvent);

      elem.removeEventListener('keydown', removeFocusEvent);

      elem.removeEventListener('keyup', removeFocusEvent);
    }
  };

  const init = () => {
    changeEvent();
    if (elem.disabled === true) {
      Utils.addClass(switchWrap, 'cb-disabled');
    }

    elem.addEventListener('change', changeEvent);

    elem.addEventListener('focus', focusEvent);

    elem.addEventListener('blur', removeFocusEvent);

    elem.addEventListener('mousedown', pressEvent);

    elem.addEventListener('mouseup', removePressEvent);

    elem.addEventListener('keydown', pressEvent);

    elem.addEventListener('keyup', removePressEvent);

    elem.toggleSwitchPlugin = 'cb';
  };

  if (elem.toggleSwitchPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

// ------------------------------------  TEXT AREA
/**
 * Resizable textarea
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.autoResize
 * @returns {{destroy: Function}}
 */
const textareaResize = (data = {}) => {
  const defaultData = {
    elem: null,
    autoResize: true,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let autoResize = data.autoResize;

  if (!Utils.elemExists(elem)) return null;

  let elemProperty = {};

  const elemInputEvent = () => {
    const scrollHeight = elem.scrollHeight;

    elem.style.overflowY = 'hidden';

    if (parseInt(elemProperty.scrollHeight, 10) < parseInt(scrollHeight, 10)) {
      elem.style.height = scrollHeight + 'px';
    }
  };

  const resetTextarea = () => {
    elem.style.height = elemProperty.height + 'px';

    elem.style.width = elemProperty.width + 'px';

    elem.style.overflowY = 'auto';

    elem.setAttribute('rows', elemProperty.rows);
  };
  const addAutoResize = () => {
    elem.style.height = elemProperty.scrollHeight + 'px';

    elem.addEventListener('input', elemInputEvent);
  };

  const elemKeydownEvent = e => {
    let rows = parseInt(elem.getAttribute('rows'), 10);
    elem.style.height = 'auto';

    if (e.shiftKey && e.ctrlKey && Utils.whichKey(e) === 'ENTER') {
      e.preventDefault();

      rows--;
      if (rows > elemProperty.rows) {
        elem.setAttribute('rows', rows);
      }
    } else if (e.ctrlKey && Utils.whichKey(e) === 'ENTER') {
      e.preventDefault();
      if (Utils.outerHeight(elem) < Utils.windowsDimension().height) {
        rows++;

        elem.setAttribute('rows', rows++);
      }
    } else if (Utils.whichKey(e) === 'ESC') {
      resetTextarea();
    }
  };

  const addKeyboardResize = () => {
    elem.addEventListener('keydown', elemKeydownEvent);
  };

  const elemKeydownEventAuto = e => {
    if (Utils.whichKey(e) === 'ESC') {
      resetTextarea();
    }
  };

  const elemMouseupEvent = () => {
    elem.cbX = !isNaN(elem.cbX) ? elem.cbX : Utils.outerWidth(elem);

    elem.cbY = !isNaN(elem.cbY) ? elem.cbY : Utils.outerHeight(elem);

    if (Utils.outerWidth(elem) !== elem.cbX || Utils.outerHeight(elem) !== elem.dcbY) {
      elemProperty.scrollHeight = elem.scrollHeight;
    }

    // set new height/width
    elem.cbX = Utils.outerWidth(elem);

    elem.cbY = Utils.outerHeight(elem);
  };

  const destroy = () => {
    if (elem.textareaResizePlugin === 'cb') {
      elem.textareaResizePlugin = null;

      elem.removeEventListener('keydown', elemKeydownEventAuto);

      elem.removeEventListener('keydown', elemKeydownEvent);

      elem.removeEventListener('mouseup', elemMouseupEvent);

      elem.removeEventListener('input', elemInputEvent);

      resetTextarea();
    }
  };

  const init = () => {
    elem.textareaResizePlugin = 'cb';

    elemProperty.rows = elem.getAttribute('rows') ? elem.getAttribute('rows') : '';

    elemProperty.height = Utils.outerHeight(elem);

    elemProperty.width = Utils.outerWidth(elem);

    elemProperty.scrollHeight = elem.scrollHeight;

    if (autoResize) {
      elem.addEventListener('keydown', elemKeydownEventAuto);

      addAutoResize();
    } else {
      addKeyboardResize();
    }

    // track textarea resize event
    elem.addEventListener('mouseup', elemMouseupEvent);
  };

  if (elem.textareaResizePlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

// ------------------------------------  FORM VALIDATION

/**
 * Input validation state
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.success
 * @param {Boolean} data.error
 * @param {String} data.wrapperClass
 * @returns {{changeState: Function}}
 * @returns {{destroy: Function}}
 */
const validationState = (data = {}) => {
  const defaultData = {
    elem: null,
    success: false,
    error: false,
    aria: true,
    wrapperClass: 'cb-validation',
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let wrapperClass = data.wrapperClass;
  let state = data.success ? 'success' : data.error ? 'error' : '';

  if (!Utils.elemExists(elem)) return false;

  // This is only working for Input elements
  if (elem.tagName !== 'INPUT') return false;

  const cbInput = Utils.getClosest(elem, '.cb-input');
  const parent = Utils.parent(elem);

  if (!Utils.elemExists(cbInput)) return false;

  const removeValidationIcon = () => {
    Utils.removeClass(cbInput, 'cb-validation-success');

    Utils.removeClass(cbInput, 'cb-validation-error');
  };

  const addIcon = () => {
    const glyphIcon = state === 'error' ? 'cb-exclamation-fill' : 'cb-check-fill';
    const icon = cbInput.querySelector('.cb-validation-icon');

    Utils.removeClass(icon, 'cb-x-mark');

    Utils.removeClass(icon, 'cb-exclamation-fill');

    Utils.addClass(icon, glyphIcon);
  };

  const addValidationIcon = () => {
    const icon = document.createElement('I');

    Utils.addClass(icon, ['cb-validation-icon', 'cb-icon']);

    Utils.attr(icon, 'aria-hidden', true);

    if (cbInput.querySelector('.cb-input-icon-left') || cbInput.querySelector('.cb-input-icon-right')) {
      const container = cbInput.querySelector('.cb-input-icon-left')
        ? cbInput.querySelector('.cb-input-icon-left')
        : cbInput.querySelector('.cb-input-icon-right');

      Utils.insertAfter(container, icon);
    } else if (!parent.matches('.cb-validation-label-input')) {
      const iconWrapper = document.createElement('DIV');

      Utils.addClass(iconWrapper, 'cb-validation-label-input');

      const children = cbInput.children;

      Utils.wrapAll(children, iconWrapper);

      Utils.insertAfter(iconWrapper, icon);
    } else {
      console.warn('Apricot-validationState, make sure you have correct markup in place');

      return false;
    }
    addIcon();
  };

  const buildValidationBlock = () => {
    removeValidationIcon();

    Utils.addClass(cbInput, wrapperClass + '-' + state);

    addValidationIcon();
  };

  const addAria = validState => {
    if (validState === 'error') {
      Utils.attr(elem, 'aria-invalid', 'true');
    } else {
      Utils.removeAttr(elem, 'aria-invalid');
    }
  };

  const changeState = (success = false, error = false) => {
    if (elem.validStatePlugin === 'cb') {
      state = success ? 'success' : error ? 'error' : '';

      removeValidationIcon();

      Utils.addClass(cbInput, wrapperClass + '-' + state);
      if (data.aria) {
        addAria(state);
      }
      addIcon();
    }
  };

  const destroy = () => {
    if (elem.validStatePlugin === 'cb') {
      elem.validStatePlugin = null;

      removeValidationIcon();

      cbInput.querySelector('.cb-validation-icon').remove();

      Utils.removeClass(cbInput.querySelector('.cb-validation-label-input'), 'cb-validation-label-input');

      Utils.removeAttr(elem, 'aria-invalid');
    }
  };

  const init = () => {
    elem.validStatePlugin = 'cb';

    buildValidationBlock();
  };

  if (elem.validStatePlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
    changeState: changeState,
  };
};

// ------------------------------------  FILE UPLOAD
/**
 * Custom file upload button
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {String} data.label
 * @param {String} data.btnType
 * @param {String} data.btnSize
 * @param {Boolean} data.fdbk
 * @param {Boolean} data.fdbkPath
 * @param {String} data.fdbkMsg
 * @param {Boolean} data.fdbkTruncate
 * @param {String|Number} data.fdbkMaxChars
 * @param {Number} data.fdbkRemove
 * @param {String} data.fdbkPosition
 * @param {String} data.ellipseText
 * @param {Function} data.fdbkValidation
 * @returns {{reset: Function}}
 * @returns {{destroy: Function}}
 */
const fileUpload = (data = {}) => {
  const defaultData = {
    elem: null,
    markup: true,

    label: 'Choose File',
    btnType: '',
    btnSize: 'sm',

    fdbk: true,
    fdbkPath: false,
    fdbkMsg: 'No file selected...',
    fdbkTruncate: true,
    fdbkMaxChars: 'auto',
    fdbkRemove: true,
    fdbkPosition: 'bottom',
    ellipseText: '...',
    fdbkValidation: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;

  let fileWrapperElem = null;
  let fileElem = null;
  let fdbkElem = null;
  let fileName = '';

  const adjustText = value => {
    var position = data.fdbkPosition ? data.fdbkPosition : 'middle',
      ellipseText = data.ellipseText ? data.ellipseText : '...';

    //don't truncate value
    if (!data.fdbk) {
      return value;
    }

    //Check text length, compare to containing span
    if (isNaN(data.fdbkMaxChars)) {
      const containerWidth = Utils.outerWidth(fileWrapperElem);
      const buttonWidth = Utils.outerWidth(fileElem);
      let valueWidth = 0;
      let maxWidth = 0;
      let fdbkMaxChars = 0;
      let tmp = value;

      const tmpElem = document.createElement('SPAN');

      Utils.addClass(tmpElem, ['cb-file-element', 'cb-tmp-element']);

      Utils.attr(tmpElem, 'id', 'tmpFileValue');

      tmpElem.innerText = value;

      document.querySelector('body').appendChild(tmpElem);

      valueWidth = Utils.outerWidth(tmpElem);
      if (data.fdbkPosition === 'bottom') {
        maxWidth = parseInt(containerWidth, 10);
      } else {
        maxWidth = parseInt(containerWidth - buttonWidth, 10);
      }

      if (maxWidth <= valueWidth) {
        //Calculate maximum number of characters for the string
        while (Utils.outerWidth(tmpElem) > maxWidth) {
          tmp = tmpElem.innerText;

          tmp = tmp.substring(0, tmp.length - 1);

          tmpElem.innerText = tmp;
        }
        fdbkMaxChars = tmp.length;

        Utils.remove(tmpElem);

        value = Utils.textTruncate(value, fdbkMaxChars, position, ellipseText);
      }
    } else if (value.length > data.fdbkMaxChars) {
      value = Utils.textTruncate(value, data.fdbkMaxChars, position, ellipseText);
    }

    return value;
  };

  //ADD EVENTS
  //===============

  const btnClick = e => {
    e.preventDefault();

    elem.click();
  };

  const reset = e => {
    e && e.preventDefault();

    let removeElem = fileWrapperElem.querySelector('.cb-file-element-rm');

    elem.value = '';

    Utils.removeAttr(fileElem, 'title');

    Utils.removeAttr(fileElem, 'data-cb-file');

    if (data.fdbk) {
      fdbkElem.innerHTML = adjustText(data.fdbkMsg);

      fileName = adjustText(data.fdbkMsg);
    } else {
      fdbkElem.innerHTML = '';

      fileName = '';
    }

    Utils.removeAttr(removeElem, 'href');

    Utils.removeClass(removeElem, 'cb-icon');

    Utils.removeClass(removeElem, 'cb-x-mark');

    // set focus back to button
    fileElem.focus();

    const event2 = new CustomEvent('apricot_fileRemoved');
    let obj = {};
    if (elem.fileObj) {
      obj = elem.fileObj;

      elem.fileObj = null;
    }
    event2.data = obj;

    elem.dispatchEvent(event2);
  };

  const elemChange = () => {
    //get file value
    let inputValue = elem.value;
    let message = '';
    const event1 = new CustomEvent('apricot_fileSelected');
    let obj = {};
    const file = elem.files[0] ? elem.files[0] : null;

    if (data.fdbkValidation && !data.fdbkValidation(inputValue)) {
      // Exit the function if the file name is invalid
      return;
    }

    // Display selected file
    if (data.fdbk) {
      message = data.fdbkMsg;

      if (inputValue) {
        message = !data.fdbkPath ? inputValue.split(/\\/).pop() : inputValue;

        fileName = inputValue;
      }

      fdbkElem.innerHTML = adjustText(message);

      if (data.fdbkRemove) {
        let removeElem = fileWrapperElem.querySelector('.cb-file-element-rm');
        if (!removeElem) {
          removeElem = document.createElement('A');

          Utils.addClass(removeElem, ['cb-file-element-rm', 'cb-icon', 'cb-x-mark']);

          Utils.attr(removeElem, 'href', '#');

          // wrap
          Utils.wrap(fdbkElem, removeElem);
        } else {
          Utils.attr(removeElem, 'href', '#');

          Utils.addClass(removeElem, ['cb-icon', 'cb-x-mark']);
        }

        removeElem.addEventListener('click', e => {
          let removeFile = fileWrapperElem.querySelector('.cb-file-element-rm');
          if (!Utils.hasClass(removeFile, 'cb-icon')) return;

          reset(e);
        });
      }
    }

    if (inputValue) {
      Utils.attr(fileElem, 'title', inputValue);

      Utils.attr(fileElem, 'data-cb-file', inputValue);

      // trigger custom event
      obj.file = file;

      obj.fullPath = inputValue;

      obj.fileName = inputValue.split(/\\/).pop();

      elem.fileObj = obj;
    } else {
      Utils.removeAttr(fileElem, 'data-cb-file');

      Utils.removeAttr(fileElem, 'title');
    }

    event1.data = obj;

    elem.dispatchEvent(event1);
  };
  const addEvents = () => {
    fileElem.addEventListener('click', btnClick);

    elem.addEventListener('change', elemChange);

    if (data.fdbk) {
      //adjust text based on font-size for current viewport
      window.addEventListener('resize', () => {
        if (fdbkElem.innerHTML !== '') {
          const value = !data.fdbkPath ? fileName.split(/\\/).pop() : fileName;

          fdbkElem.innerHTML = adjustText(value);
        }
      });
    }
  };

  const destroy = () => {
    if (elem.fileUploadPlugin === 'cb') {
      fileElem && fileElem.removeEventListener('click', btnClick);

      elem && elem.removeEventListener('change', elemChange);

      elem.fileUploadPlugin = null;
    }
  };

  const init = () => {
    elem.fileUploadPlugin = 'cb';

    if (data.markup) {
      fileWrapperElem = document.createElement('DIV');

      Utils.addClass(fileWrapperElem, 'cb-file-upload');

      Utils.wrap(elem, fileWrapperElem);

      if (data.fdbk) {
        fdbkElem = document.createElement('SPAN');

        Utils.addClass(fdbkElem, 'cb-file-element');

        Utils.insertAfter(elem, fdbkElem);
      }

      fileElem = document.createElement('BUTTON');

      fileElem.innerHTML = data.label;

      Utils.attr(fileElem, 'type', 'button');

      Utils.addClass(fileElem, ['cb-file-button', 'cb-btn', 'cb-btn-' + data.btnSize]);

      if (data.btnType !== '') {
        Utils.addClass(fileElem, 'cb-btn-' + data.btnType);
      }

      Utils.insertAfter(elem, fileElem);
    } else {
      fileWrapperElem = Utils.parent(elem);

      fdbkElem = fileWrapperElem.querySelector('.cb-file-element');

      fileElem = fileWrapperElem.querySelector('button');
    }

    addEvents();
  };

  if (elem.fileUploadPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
    reset: reset,
  };
};

// ------------------------------------  INPUT DROPDOWN (AUTOFILL)
/**
 * Input Dropdown
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {String} data.event
 * @param {Boolean} data.icon
 * @param {Boolean} data.iconLeft
 * @param {Boolean} data.escClose
 * @param {Boolean} data.clearInput
 * @param {Boolean} data.closeOnSelect
 * @param {Boolean} data.closeOnClickOutside
 * @param {Number} data.scrollLimit
 * @param {Number} data.itemHeight
 * @param {Boolean} data.scrollLimitHelper
 * @param {String} data.scrollLimitHelperText
 * @param {Array} data.dataItems
 * @param {Boolean} data.defaultItems
 * @param {Array} data.itemsText
 * @param {String} data.noItemsText
 * @param {Function} data.callBack
 * @param {Function} data.onBuild
 * @returns {{destroy: Function}}
 *
 */
const inputDropdown = (data = {}) => {
  const defaultData = {
    elem: null,
    event: '',
    icon: false,
    iconLeft: false,
    escClose: true,
    clearInput: true,
    closeOnSelect: true,
    closeOnClickOutside: true,
    scrollLimit: 0,
    itemHeight: 40,
    scrollLimitHelper: false,
    scrollLimitHelperText: 'There are more results available. Keep typing to refine your search.',
    dataItems: [],
    defaultItems: false,
    itemsText: ['suggestion available', 'suggestions available'],
    noItemsText: 'no suggestions available',
    callBack: null,
    onBuild: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let input = null;
  let label = null;
  let dropdown = null;
  let dropdownUl = null;
  let dropdownHelper = null;
  let dropdownContainer = null;
  let scrollLimitHelper = data.scrollLimitHelper;
  let hasOverlay = false;
  let state = false;
  let cInput = null;
  let itemsCount = 0;

  if (!Utils.elemExists(elem)) return null;

  const adjustDropdownHelperHeight = () => {
    const maxHeight = data.scrollLimit * data.itemHeight + 20;
    if (itemsCount > data.scrollLimit) {
      Utils.addClass(dropdownHelper, 'cb-show');

      dropdownHelper.innerText = data.scrollLimitHelperText;
      const helperHeight = Utils.getHiddenHeight(dropdownHelper, true);

      dropdownContainer.style.paddingBottom = `${helperHeight}px`;

      dropdownContainer.style.minHeight = `${helperHeight + maxHeight}px`;
    } else {
      Utils.removeClass(dropdownHelper, 'cb-show');

      dropdownContainer.style.paddingBottom = '0px';

      dropdownContainer.style.minHeight = '0px';

      dropdownHelper.innerText = '';
      if (hasOverlay && itemsCount > 0) {
        dropdownContainer.style.minHeight = `${itemsCount * data.itemHeight + 20}px`;
      }
    }
  };

  const getFocusableNodes = () => {
    return dropdownUl.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);
  };

  const toggleDropdown = (mode, focus) => {
    // only run if we have a state change

    if (mode !== state) {
      // open
      if (mode) {
        // only open if there is something to show
        if (dropdownUl && dropdownUl.querySelectorAll('li').length > 0) {
          if (
            dropdownUl.querySelectorAll('li').length === 1 &&
            Utils.attr(dropdownUl.querySelectorAll('li')[0], 'data-cb-value') === Utils.attr(elem, 'data-cb-value') &&
            !focus
          ) {
            // ignore
          } else {
            Utils.addClass(elem, 'cb-open');
          }
        }
      } else {
        Utils.removeClass(elem, 'cb-open');
      }

      state = Utils.hasClass(elem, 'cb-open');

      const event = new CustomEvent('apricot_inputDropdown');

      event.data = {
        type: state ? 'open' : 'close',
      };

      elem.dispatchEvent(event);

      if (!state) {
        input.focus();
      }
    }

    if (Utils.hasClass(elem, 'cb-open') && focus) {
      getFocusableNodes().length > 0 && getFocusableNodes()[0].focus();
    }
  };

  const A11yEvents = () => {
    dropdownUl.querySelectorAll('li').forEach(item => {
      item.addEventListener('click', e => {
        e.preventDefault();

        const newValue = Utils.attr(item, 'data-cb-value');

        input.value = newValue;

        // for floating label
        const event1 = new Event('input');

        input.dispatchEvent(event1);

        // for clearInput
        const event2 = new Event('keyup');

        input.dispatchEvent(event2);

        if (!Utils.isBlank(newValue)) {
          let obj = {};
          obj.value = Utils.attr(item, 'data-cb-obj-value') ? Utils.attr(item, 'data-cb-obj-value') : '';

          obj.label = Utils.attr(item, 'data-cb-obj-label') ? Utils.attr(item, 'data-cb-obj-label') : '';

          // call callback function
          if (data.callBack) {
            data.callBack(e, newValue, obj);
          } else {
            const event = new CustomEvent('apricot_inputDropdown');

            event.data = {
              type: 'change',
              value: newValue,
              dropdownObj: obj,
            };

            elem.dispatchEvent(event);
          }

          if (data.closeOnSelect) {
            toggleDropdown(false);
          }
        }
      });
    });

    Array.prototype.forEach.call(getFocusableNodes(), node => {
      node.addEventListener('keydown', e => {
        const k = e.which || e.keyCode;
        let index = 0;
        const items = dropdown.querySelectorAll('a');

        Array.prototype.forEach.call(items, function (item, i) {
          if (node === item) {
            index = i;
          }
        });

        //make sure menus are closed after tab away
        if (k === 9 && index === items.length - 1) {
          //make sure menus are closed after tab away
          toggleDropdown(false);
        }

        // 38: up
        // 40: down
        // 16: shift
        if (!/(38|40|16)/.test(k)) return;

        e.preventDefault();

        e.stopPropagation();

        //up/down arrows
        if (k === 38) {
          index--; //up
        } else if (k === 40) {
          index++; //down
        }

        if (index < 0 && k === 38) {
          input.focus();

          return;
        }

        if (index < 0 || index === items.length) {
          return;
        }

        const newActive = items.item(index);

        newActive.focus();
      });
    });
  };

  const buildList = items => {
    dropdownUl = dropdown.querySelector('ul');

    // clear list
    dropdownUl.innerHTML = '';

    itemsCount = items.length;

    // build new list
    if (items.length > 0) {
      if (data.onBuild) {
        data.onBuild(dropdownUl, items);
      } else {
        Array.prototype.forEach.call(items, item => {
          const li = document.createElement('LI');

          Utils.attr(li, 'data-cb-value', item.label);

          Utils.attr(li, 'data-cb-obj-label', item.label);

          Utils.attr(li, 'data-cb-obj-value', item.value);

          Utils.attr(li, 'role', 'presentation');

          const a = document.createElement('a');

          Utils.attr(a, 'href', '#');

          Utils.attr(a, 'role', 'option');

          const span = document.createElement('span');

          span.innerHTML = item.label;

          const icon = document.createElement('span');
          if (data.icon) {
            Utils.addClass(icon, ['cb-icon', item.icon]);

            Utils.attr(icon, 'aria-hidden', true);

            if (data.iconLeft) {
              Utils.addClass(dropdownUl, 'cb-dropdown-left-icon');

              a.appendChild(icon);
            }
          }
          a.appendChild(span);

          if (data.icon && !data.iconLeft) {
            a.appendChild(icon);
          }
          li.appendChild(a);

          dropdownUl.appendChild(li);
        });
      }
      A11yEvents();

      // Open menu when we have data
      toggleDropdown(true);
    } else {
      toggleDropdown(false, true);
      if (data.defaultItems && data.dataItems.length > 0 && Utils.isBlank(input.value)) {
        buildList(data.dataItems);
      }
    }

    const itemsInfo = elem.querySelector('.cb-dropdown-input-items');
    if (Utils.elemExists(itemsInfo)) {
      if (itemsCount > 0) {
        const msg = itemsCount === 1 ? data.itemsText[0] : data.itemsText[1];
        if (scrollLimitHelper && itemsCount > data.scrollLimit) {
          itemsInfo.innerHTML = `${items.length} ${msg}. ${data.scrollLimitHelperText}`;
        } else {
          itemsInfo.innerHTML = `${items.length} ${msg}`;
        }
      } else if (!Utils.isBlank(input.value)) {
        itemsInfo.innerHTML = data.noItemsText;
      } else {
        itemsInfo.innerHTML = '';
      }
    }

    scrollLimitHelper && adjustDropdownHelperHeight();
  };

  const updateDropDown = e => {
    let items = [];

    if (e) items = e.data ? e.data : [];

    buildList(items);
  };

  const escA11Y = e => {
    if (e.keyCode === 27) {
      if (Utils.hasClass(elem, 'cb-open')) {
        toggleDropdown(false);
      }
    }
  };

  const closeA11Y = e => {
    if (e.type === 'click') {
      if (!Utils.hasClass(elem, 'cb-open') || elem.contains(e.target)) {
        return;
      }

      toggleDropdown(false);
    }
  };

  const closeOnClickOutside = () => {
    document.addEventListener('keydown', closeA11Y, true);

    document.addEventListener('click', closeA11Y, true);
  };

  const closeOnEsc = () => {
    document.addEventListener('keydown', escA11Y, true);
  };

  const inputKeyupEvents = () => {
    const oldValue = Utils.attr(elem, 'data-cb-value') ? Utils.attr(elem, 'data-cb-value') : '';
    const newValue = Utils.getValue(input) ? Utils.getValue(input) : '';

    if (Utils.isBlank(newValue)) {
      Utils.removeAttr(elem, 'data-cb-value');
    } else {
      Utils.attr(elem, 'data-cb-value', newValue);
    }

    if (newValue !== oldValue) {
      const customEvent1 = new CustomEvent('apricot_inputDropdown');

      customEvent1.data = {
        type: 'change',
        value: newValue,
      };

      elem.dispatchEvent(customEvent1);
    }
  };

  // open on arrow down
  const inputKeydownEvents = e => {
    const k = e.which || e.keyCode;
    const key = Utils.whichKey(e);

    if (key !== 'TAB' && key !== 'DOWN') return;

    if (k === 9) {
      toggleDropdown(false);

      return;
    }

    e.preventDefault();

    e.stopPropagation();

    if (k === 40) {
      toggleDropdown(true, true);
    }
  };

  const destroy = () => {
    if (elem.inputDropdownPlugin === 'cb') {
      elem.inputDropdownPlugin = null;

      elem.removeEventListener(data.event, updateDropDown);

      input.removeEventListener('keyup', inputKeyupEvents);

      input.removeEventListener('keydown', inputKeydownEvents);

      Utils.removeAttr(input, 'autocomplete');

      if (data.closeOnClickOutside) {
        document.removeEventListener('keydown', closeA11Y, true);

        document.removeEventListener('click', closeA11Y, true);
      }
      if (data.closeOnEsc) {
        document.addEventListener('keydown', escA11Y, true);
      }

      if (data.clearInput && cInput) {
        cInput.destroy();

        input.removeEventListener('apricot_clearValue', () => toggleDropdown(false));
      }
      if (scrollLimitHelper) {
        window.removeEventListener('resize', adjustDropdownHelperHeight);
      }
    }
  };

  const init = () => {
    elem.inputDropdownPlugin = 'cb';

    input = elem.querySelector('input');
    if (!Utils.elemExists(input)) return null;

    dropdown = elem.querySelector('.cb-dropdown-menu');
    if (!Utils.elemExists(dropdown)) return null;

    label = elem.querySelector('label');
    if (Utils.elemExists(label)) {
      const labelId = Utils.attr(label, 'id') ? Utils.attr(label, 'id') : Utils.uniqueID(5, 'apricot_');

      Utils.attr(label, 'id', labelId);

      dropdownUl = dropdown.querySelector('ul');

      dropdownUl && Utils.attr(dropdownUl, 'aria-labelledby', labelId);
    }

    if (scrollLimitHelper) {
      dropdownHelper = elem.querySelector('.cb-dropdown-helper');

      dropdownContainer = elem.querySelector('.cb-dropdown-container');

      if (!Utils.elemExists(dropdownContainer) || !Utils.elemExists(dropdownHelper)) {
        scrollLimitHelper = false;
      }
    }

    // Activate clear input
    if (data.clearInput) {
      cInput = clearInput({
        elem: input,
      });

      input.addEventListener('apricot_clearValue', () => toggleDropdown(false));
    }

    // check if input has value
    input.addEventListener('keyup', inputKeyupEvents);

    input.addEventListener('keydown', inputKeydownEvents);

    elem.addEventListener(data.event, updateDropDown);

    if (data.closeOnClickOutside) {
      closeOnClickOutside();
    }

    if (data.escClose) {
      closeOnEsc();
    }

    if (data.scrollLimit > 0) {
      Utils.addClass(elem, 'cb-input-dropdown-scroll');
      const maxHeight = `${data.scrollLimit * data.itemHeight + 20}px`;

      dropdown.style.maxHeight = maxHeight;

      if (scrollLimitHelper) {
        if (Utils.hasClass(elem, 'cb-input-dropdown-overlay')) {
          hasOverlay = true;
          if (dropdownHelper) {
            dropdownHelper.style.top = maxHeight;
          }
          if (dropdownContainer) {
            Utils.addClass(dropdownContainer, 'cb-has-dropdown-overlay');
          }
        }
        window.addEventListener('resize', adjustDropdownHelperHeight);
      }
    }

    if (data.dataItems.length > 0) {
      buildList(data.dataItems);
    }
  };

  if (elem.inputDropdownPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

// ------------------------------------  PASSWORD INPUT
/**
 * Show/Hide Password value
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @returns {{destroy: Function}}
 */

const passwordInput = (data = {}) => {
  const defaultData = {
    elem: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  const elem = data.elem;
  if (!Utils.elemExists(elem)) return null;

  let btn = null;

  const btnEvents = e => {
    e.preventDefault();

    e.stopPropagation();

    const type = Utils.attr(elem, 'type');
    const icon = btn.querySelector('.cb-icon');

    if (type === 'text') {
      Utils.attr(elem, 'type', 'password');

      Utils.addClass(icon, 'cb-see-off');

      Utils.removeClass(icon, 'cb-see-on');

      Utils.attr(btn, 'aria-pressed', 'false');
    } else {
      Utils.attr(elem, 'type', 'text');

      Utils.removeClass(icon, 'cb-see-off');

      Utils.addClass(icon, 'cb-see-on');

      Utils.attr(btn, 'aria-pressed', 'true');
    }
  };

  const destroy = () => {
    if (elem.passwordInputPlugin === 'cb') {
      elem.passwordInputPlugin = null;

      Utils.attr(elem, 'type', 'password');

      btn.removeEventListener('click', btnEvents);
    }
  };

  const init = () => {
    elem.passwordInputPlugin = 'cb';

    btn = Utils.getClosest(elem, '.cb-input').querySelector('.cb-btn');
    if (!Utils.elemExists(btn)) return null;

    if (elem.disabled === true) {
      Utils.addClass(elem, 'cb-disabled');

      // Utils.addClass(btn, 'cb-disabled')
      Utils.attr(btn, 'disabled', 'true');

      return null;
    }
    const idElem = Utils.attr(elem, 'id') ? Utils.attr(elem, 'id') : Utils.uniqueID(5, 'apricot_');

    Utils.attr(elem, 'id', idElem);

    Utils.attr(btn, 'aria-controls', idElem);

    Utils.attr(btn, 'aria-pressed', 'false');

    btn.addEventListener('click', btnEvents);
  };

  if (elem.passwordInputPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

// ------------------------------------  INPUT STEPPER
/**
 * Increase/Decrease Numeric Input Value
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.helper
 * @param {Number} data.step
 * @param {Number} data.min
 * @param {Number} data.max
 * @param {String} data.helperMsg
 * @param {String} data.errMsg
 * @returns {{destroy: Function}}
 */

const stepperInput = (data = {}) => {
  const defaultData = {
    elem: null,
    helper: null,
    step: 1,
    min: null,
    max: null,
    helperMsg: null,
    errMsg: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  const elem = data.elem;
  if (!Utils.elemExists(elem)) return null;
  const helper = data.helper;
  let parent = null;

  let btnDecrease = null;
  let btnIncrease = null;
  let idHelper = '';
  let errMsg;
  let helperMsg;

  const step = isNaN(data.step) ? 1 : parseInt(data.step);

  // 0: remove
  // 1: add
  const updateAriaDescribedBy = (mode, node, helperId) => {
    let ariaDescribedby = Utils.attr(node, 'data-cb-describedby') || null;

    if (mode) {
      if (ariaDescribedby) {
        Utils.attr(node, 'aria-describedby', `${helperId} ${ariaDescribedby}`);
      } else {
        Utils.attr(node, 'aria-describedby', helperId);
      }
    } else {
      Utils.removeAttr(node, 'aria-describedby');
      if (ariaDescribedby) {
        Utils.attr(node, 'aria-describedby', ariaDescribedby);
      }
    }
  };

  const validState = value => {
    if (!Utils.elemExists(data.helper)) return;
    if (value < data.min || value > data.max) {
      Utils.removeClass(parent, 'cb-validation-state');

      Utils.addClass(parent, 'cb-validation-error');

      Utils.addClass(helper, 'cb-validation-error');

      updateAriaDescribedBy(1, elem, idHelper);

      Utils.attr(elem, 'aria-invalid', true);

      helper.innerHTML = errMsg;
    } else {
      Utils.addClass(parent, 'cb-validation-state');

      Utils.removeClass(parent, 'cb-validation-error');

      Utils.removeClass(helper, 'cb-validation-error');

      updateAriaDescribedBy(0, elem);

      Utils.removeAttr(elem, 'aria-invalid');

      helper.innerHTML = helperMsg;
    }
  };

  const buttonCheck = (value, set) => {
    if (Utils.elemExists(helper)) validState(value);

    if (value < data.min + data.step) {
      Utils.attr(btnDecrease, 'disabled', true);
      if (set) elem.value = data.min;
    } else {
      Utils.removeAttr(btnDecrease, 'disabled');
    }

    if (value > data.max - data.step) {
      Utils.attr(btnIncrease, 'disabled', true);
      if (set) elem.value = data.max;
    } else {
      Utils.removeAttr(btnIncrease, 'disabled');
    }
  };

  const btnDecreaseEvents = e => {
    e.preventDefault();

    e.stopPropagation();

    const value = elem.value;
    let count = parseInt(value) - step;
    elem.value = count;

    data.min && buttonCheck(count);
  };

  const btnIncreaseEvents = e => {
    e.preventDefault();

    e.stopPropagation();

    const value = elem.value;
    let count = parseInt(value) + step;
    elem.value = count;

    data.max && buttonCheck(count);
  };

  const imposeMinMax = () => {
    if (!data.min || !data.max) return;

    if (typeof data.min !== 'number' || typeof data.max !== 'number' || Utils.isBlank(elem)) return;
    const v = elem.value;

    buttonCheck(v, true);
  };

  const destroy = () => {
    if (elem.stepperInputPlugin === 'cb') {
      elem.stepperInputPlugin = null;

      btnDecrease.removeEventListener('click', btnDecreaseEvents);

      btnIncrease.removeEventListener('click', btnIncreaseEvents);

      elem.removeEventListener('change', imposeMinMax);
    }
  };

  const init = () => {
    elem.stepperInputPlugin = 'cb';

    btnDecrease = Utils.getClosest(elem, '.cb-stepper-input').querySelector('.cb-stepper-decrease');

    btnIncrease = Utils.getClosest(elem, '.cb-stepper-input').querySelector('.cb-stepper-increase');

    if (!Utils.elemExists(btnDecrease) || !Utils.elemExists(btnIncrease)) return null;

    const idElem = Utils.attr(elem, 'id') ? Utils.attr(elem, 'id') : Utils.uniqueID(5, 'apricot_');

    Utils.attr(elem, 'id', idElem);

    if (Utils.elemExists(helper)) {
      idHelper = Utils.attr(helper, 'id') ? Utils.attr(helper, 'id') : Utils.uniqueID(5, 'apricot_');

      Utils.attr(helper, 'id', idHelper);

      parent = Utils.getClosest(elem, '.cb-input');

      helperMsg = data.helperMsg ? data.helperMsg : helper.innerText ? helper.innerText : '';
    }

    Utils.attr(btnDecrease, 'aria-controls', idElem);

    Utils.attr(btnIncrease, 'aria-controls', idElem);

    btnDecrease.querySelector('.sr-only').innerText = `Decrease ${step}`;

    btnIncrease.querySelector('.sr-only').innerText = `Increase ${step}`;

    if (data.min && data.max) {
      elem.value = data.min;

      Utils.attr(elem, 'min', data.min);

      Utils.attr(elem, 'max', data.max);

      buttonCheck(elem.value);

      errMsg = data.errMsg ? data.errMsg : `Please enter a value between ${data.min} and ${data.max}`;
    }

    if (elem.disabled === true) {
      Utils.addClass(elem, 'cb-disabled');

      Utils.attr(btnDecrease, 'disabled', 'true');

      Utils.attr(btnIncrease, 'disabled', 'true');

      return null;
    }

    btnDecrease.addEventListener('click', btnDecreaseEvents);

    btnIncrease.addEventListener('click', btnIncreaseEvents);

    elem.addEventListener('blur', imposeMinMax);
  };

  if (elem.stepperInputPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

const CBForm = {
  customSelectElement,
  customFormElement,
  floatingLabel,
  toggleSwitch,
  textareaResize,
  validationState,
  fileUpload,
  clearInput,
  inputDropdown,
  passwordInput,
  stepperInput,
};

if (typeof window !== 'undefined') {
  window.cb = window.cb || {};

  window.cb.apricot = window.cb.apricot || {};

  window.cb.apricot.CBForm = CBForm;
}

export default CBForm;
