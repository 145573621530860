/* ========================================================================
 * Apricot's Modals
 * ========================================================================
 *
 * It has the ability to display multiple modals at the same time
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/modal.scss';
import '../scss/includes/button.scss';

// javaScript
import Utils from './CBUtils';
/**
 * Modal
 *
 * @class
 * @param {Object} data
 * @param {Element|String} data.elem
 * @param {Element|String} data.trigger
 * @param {Element|String} data.focusElem
 * @param {Boolean} data.nested
 * @param {Boolean} data.shadowRoot
 * @param {Boolean} data.videoModal
 * @param {Boolean} data.promoModal
 * @param {String} data.closeAttr
 * @param {Boolean} data.disableFocus
 * @param {Boolean} data.disableScroll
 * @param {Boolean} data.disableHeightAdjustment
 * @param {Boolean} data.disableHeightAdjustmentAria
 * @param {Boolean} data.escClose
 * @param {Boolean} data.awaitOpenAnimation
 * @param {Boolean} data.awaitCloseAnimation
 * @param {Boolean} data.openAnimation
 * @param {Boolean} data.closeAnimation
 * @param {Boolean} data.controlled
 * @param {Boolean} data.analytics
 * @param {String} data.analyticsTitle
 * @param {Boolean} data.analyticsOnClose
 * @param {Function} data.onShow
 * @param {Function} data.onClose
 * @returns {{show: Function}}
 * @returns {{close: Function}}
 * @returns {{adjustHeight: Function}}
 * @returns {{destroy: Function}}
 */

const Modal = (data = {}) => {
  const defaultData = {
    elem: null,
    trigger: null,
    focusElem: null,
    shadowRoot: false,
    nested: false,
    videoModal: false,
    promoModal: false,
    closeAttr: 'data-cb-modal-close',
    disableFocus: false,
    disableScroll: false,
    disableHeightAdjustment: false,
    disableHeightAdjustmentAria: false,
    escClose: true,
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    openAnimation: true,
    closeAnimation: true,
    controlled: false,
    analytics: false,
    analyticsTitle: null,
    analyticsOnClose: false,
    onShow: () => {},
    onClose: () => {},
  };

  data = {
    ...defaultData,
    ...data,
  };

  const elem = typeof data.elem === 'string' ? document.getElementById(data.elem) : data.elem;

  if (!Utils.elemExists(elem)) return null;

  let trigger = null;
  let focusElem = null;
  let activeElement = null;

  let modalContainer = null;
  let modalHeader = null;
  let modalContent = null;
  let modalFooter = null;
  let imgBlock = null;

  let elemId = '';
  let adjustableContent = null;
  let videoIframe = null;
  let videoSrc = '';
  let analyticsTitle = '';

  // For React modal, Controlled
  if (elem.modalPlugin !== 'cb' && !!data.controlled) {
    elem.modalPlugin = 'cb';
  }

  const modalComponents = () => {
    trigger = typeof data.trigger === 'string' ? document.getElementById(data.trigger) : data.trigger;

    focusElem = typeof data.focusElem === 'string' ? document.getElementById(data.focusElem) : data.focusElem;

    modalContainer = elem.querySelector('.cb-modal-container');

    modalHeader = elem.querySelector('.cb-modal-header');

    modalContent = elem.querySelector('.cb-modal-content');

    modalFooter = elem.querySelector('.cb-modal-footer');

    imgBlock = data.promoModal ? elem.querySelector('.cb-promo-modal-img') : null;

    // make sure Modal has ID
    elemId = Utils.attr(elem, 'id') ? Utils.attr(elem, 'id') : Utils.uniqueID(5, 'apricot_');

    Utils.attr(elem, 'id', elemId);

    if (modalContent) {
      if (modalContent.querySelector('.cb-notification')) {
        adjustableContent = modalContent.querySelector('.cb-notification-content');
      } else {
        adjustableContent = modalContent;
      }
    } else {
      console.warn('Apricot Modal: Seems like your modal has no content block.');
    }

    // needed for style adjustment
    if (modalHeader && modalHeader.querySelector('.cb-btn-close')) {
      Utils.addClass(modalHeader, 'cb-modal-has-close');
    }

    // video Modal
    if (data.videoModal) {
      videoIframe = elem.querySelector('iframe');
      if (videoIframe) {
        videoSrc = Utils.attr(videoIframe, 'data-cb-src');
      }
    }

    // activate analytics tracker
    if (data.analytics) {
      const title = elem.querySelector('.cb-modal-title');
      const dialogTitle = elem.querySelector('.cb-notification-title');

      analyticsTitle = data.analyticsTitle
        ? data.analyticsTitle
        : Utils.elemExists(title)
          ? title.textContent || title.innerText
          : Utils.elemExists(dialogTitle)
            ? dialogTitle.textContent || dialogTitle.innerText
            : `missing title - ${elemId}`;

      Utils.attr(modalContainer, 'data-cbtrack-modal', analyticsTitle);
    }
  };

  const resetHeight = () => {
    if (data.videoModal) {
      if (!modalContainer) return;
      modalContainer.style.overflowY = '';

      modalContainer.style.height = '';
    } else {
      if (!adjustableContent) return;
      if (!adjustableContent.hasChildNodes()) return;

      adjustableContent.style.overflowY = '';

      adjustableContent.style.height = '';
      if (!data.disableHeightAdjustmentAria) {
        Utils.removeAttr(adjustableContent, 'tabindex');

        Utils.removeAttr(adjustableContent, 'role');

        Utils.removeAttr(adjustableContent, 'aria-label');
      }
    }
  };

  const calculateHeight = () => {
    if (!Utils.hasClass(elem, 'cb-open')) return;

    if (data.disableHeightAdjustment) return;

    // no content
    if (!adjustableContent) return;
    // empty content
    if (!adjustableContent.hasChildNodes()) return;
    if (!Utils.hasClass(elem, 'cb-open')) return;

    resetHeight();

    // Only calculate when the modal is open
    if (data.videoModal) {
      let pageHeight = window.innerHeight;
      let pageWidth = window.innerWidth;
      let height = parseInt(pageHeight, 10) - 96;
      let width = parseInt(pageWidth, 10) - 96;
      let w = 0;
      let h = 0;

      if (pageWidth < pageHeight) {
        w = (width * 98) / 100;

        h = Math.round((w * 9) / 16);
      } else {
        h = (height * 98) / 100;

        w = Math.round((h * 16) / 9);
      }

      modalContainer.style.width = w + 'px';

      modalContainer.style.height = h + 'px';
    } else if (Utils.hasClass(elem, 'cb-open')) {
      let ch = Utils.height(modalContainer);
      let mh = Utils.height(adjustableContent);
      let hh = 0;
      let fh = 0;
      let newHeight = 0;

      if (modalHeader) hh = Utils.outerHeight(modalHeader);
      if (modalFooter) {
        fh = Utils.outerHeight(modalFooter);
      } else {
        // GS-9130
        // fh = 24;
        fh = 0;
      }

      let mpAdjust = 24 * 3;
      let header = null;

      if (modalContent.querySelector('.cb-notification')) {
        header = modalContent.querySelector('.cb-notification-header');
      }

      if (modalContent.querySelector('.cb-notification')) {
        fh = 0;

        mpAdjust = 24 * 2 + 12;

        hh = Utils.outerHeight(header);
      }

      // promo Modal, mobile header (stacked)
      if (data.promoModal) {
        const prefix = Utils.viewport().prefix;
        const promoTop = Utils.elemExists(elem.querySelector('.cb-promo-modal-top'));
        if (Utils.elemExists(imgBlock) && (prefix === 'xs' || promoTop)) {
          const ph = Utils.outerHeight(imgBlock);

          ch = ch - ph;
        }
      }
      newHeight = ch - mpAdjust - hh - fh;

      if (mh > newHeight) {
        adjustableContent.style.overflowY = 'auto';

        adjustableContent.style.height = newHeight + 'px';
        if (!data.disableHeightAdjustmentAria) {
          Utils.attr(adjustableContent, 'tabindex', '0');

          Utils.attr(adjustableContent, 'role', 'region');

          Utils.attr(adjustableContent, 'aria-label', 'scrollable content');
        }
      } else {
        resetHeight();
      }
    }
  };

  const windowOnResize = () => {
    calculateHeight();
  };

  // add/remove scroll option from body
  const scrollBehaviour = toggle => {
    if (!data.disableScroll) return;
    const body = document.querySelector('body');
    switch (toggle) {
      case 'enable':
        body.style = {
          ...body.style,
          ...{
            overflow: '',
            height: '',
          },
        };
        break;
      case 'disable':
        body.style = {
          ...body.style,
          ...{
            overflow: 'hidden',
            height: '100vh',
          },
        };
        break;
      default:
    }
  };

  const endAnimation = () => {
    Utils.removeClass(elem, 'cb-open');

    elem.removeEventListener('animationend', endAnimation, false);

    // dispatch modal close
    const event = new CustomEvent('apricot_modalClose');

    activeElement && activeElement.dispatchEvent(event);

    elem.dispatchEvent(event);
  };

  // true: onShow
  // false: onClose
  const trackAnalytics = mode => {
    const eventName = mode ? 'cbTrack-modalOpen' : 'cbTrack-modalClose';

    document.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        detail: {
          modalName: analyticsTitle,
          modalEl: modalContainer,
        },
      }),
    );
  };

  const closeModal = options => {
    if (!elem) return;

    // only close the actual modal
    if (!!data.nested && options.activeModalID) {
      if (options.activeModalID !== elemId) {
        return;
      }
    }

    elem.setAttribute('aria-hidden', 'true');

    // make sure the src is removed when modal closes
    if (data.videoModal) {
      if (videoIframe) {
        videoIframe.src = '';
      }
    }

    scrollBehaviour('enable');

    // set focus to active elem, A11Y
    activeElement && activeElement.focus();

    if (options && options.onClose) {
      options.onClose(elem, options.source);
    } else if (data.onClose) {
      data.onClose(elem, options.source);
    }

    if (data.awaitCloseAnimation) {
      // dispatch modal start close
      const event = new CustomEvent('apricot_modalClose_start');

      activeElement && activeElement.dispatchEvent(event);

      elem.dispatchEvent(event);

      // animationend event is fired when a CSS Animation has completed
      elem.addEventListener('animationend', endAnimation, false);
    } else {
      Utils.removeClass(elem, 'cb-open');

      // dispatch modal close
      const event = new CustomEvent('apricot_modalClose');

      activeElement && activeElement.dispatchEvent(event);

      elem.dispatchEvent(event);
    }

    // trigger analytics tracker
    if (data.analytics && data.analyticsOnClose) {
      trackAnalytics(false);
    }

    const body = document.getElementsByTagName('body')[0];

    Utils.removeClass(body, 'cb-modal-open');

    resetHeight();

    // React Modal, Controlled
    if (data.controlled) {
      // eslint-disable-next-line no-use-before-define
      destroy();
    }
  };

  const modalOnClick = event => {
    let node = event.target;
    const parent = Utils.parent(node);
    if (parent.tagName === 'BUTTON') {
      node = parent;
    }
    if (node.hasAttribute(data.closeAttr)) {
      let src = '';

      if (Utils.hasClass(node, 'cb-modal-overlay')) {
        src = 'overlay';
      } else if (Utils.hasClass(node, 'cb-btn-close') && Utils.hasClass(node, 'cb-btn-greyscale')) {
        src = 'close';
      } else if (Utils.hasClass(node, 'cb-btn')) {
        let btnID = '';
        if (Utils.attr(node, 'id')) {
          btnID = `_${Utils.attr(node, 'id')}`;
        }
        src = `button${btnID}`;
      }

      let obj = {};
      if (src !== '') obj = { ...{ source: src } };
      if (data.nested) {
        const closestModal = node.closest('.cb-modal');
        if (closestModal) {
          obj = {
            ...obj,
            ...{ activeModalID: Utils.attr(closestModal, 'id') },
          };
        }
      }

      closeModal(obj);

      event.preventDefault();
    }
  };

  const getFocusableNodes = () => {
    const nodes = elem.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);

    return Array(...nodes);
  };

  const maintainFocus = event => {
    const focusableNodes = getFocusableNodes();
    const active = data.shadowRoot ? Utils.getActiveElementShadowRoot() : document.activeElement;

    // if disableFocus is true
    if (!elem.contains(active)) {
      focusableNodes[0].focus();
    } else {
      const focusedItemIndex = focusableNodes.indexOf(active);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableNodes[focusableNodes.length - 1].focus();

        event.preventDefault();
      }

      if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
        focusableNodes[0].focus();

        event.preventDefault();
      }
    }
  };

  const modalOnKeyDown = event => {
    const body = document.getElementsByTagName('body')[0];
    // If toast esc is not in place
    if (event.keyCode === 27 && data.escClose && Utils.hasClass(elem, 'cb-open') && !Utils.attr(body, 'data-cb-esc')) {
      closeModal({ source: 'keyboard' });
    }

    if (!Utils.hasClass(elem, 'cb-photo-gallery-modal')) {
      if (event.keyCode === 9) maintainFocus(event);
    }
  };

  const addEventListeners = () => {
    if (elem.modalPluginEvent) return;
    elem.modalPluginEvent = true;

    if (data.openAnimation === false) {
      Utils.addClass(elem, 'cb-no-animation-open');
    }

    if (data.closeAnimation === false) {
      Utils.addClass(elem, 'cb-no-animation-close');
    }

    elem.addEventListener('touchstart', modalOnClick, {
      passive: true,
    });

    elem.addEventListener('click', modalOnClick);

    document.addEventListener('keydown', modalOnKeyDown);

    window.addEventListener('resize', windowOnResize);
  };
  const setFocusToFirstNode = () => {
    if (data.disableFocus) return;

    const dialog = elem.querySelector('div[role="dialog"]');
    if (dialog) {
      // set focus to specific element when requested
      if (Utils.elemExists(focusElem)) {
        focusElem.focus();
      } else {
        Utils.attr(dialog, 'tabIndex', '0');

        dialog.focus();
      }
    } else {
      const focusableNodes = getFocusableNodes();
      if (focusableNodes.length) focusableNodes[0].focus();
    }
  };

  const startAnimation = () => {
    calculateHeight();

    setFocusToFirstNode();

    scrollBehaviour('disable');

    data.onShow && data.onShow(elem);

    elem.removeEventListener('animationend', startAnimation, false);

    // dispatch modal show
    const event = new CustomEvent('apricot_modalShow');

    activeElement && activeElement.dispatchEvent(event);

    elem.dispatchEvent(event);
  };

  const showModal = e => {
    if (e) e.preventDefault();
    // make sure we have all events
    addEventListeners();

    // this should be the trigger
    activeElement = document.activeElement;

    elem.setAttribute('aria-hidden', 'false');

    if (data.videoModal) {
      if (videoIframe) {
        videoIframe.src = videoSrc;
      }
    }

    Utils.addClass(elem, 'cb-open');

    if (data.awaitOpenAnimation) {
      // dispatch modal start show
      const event = new CustomEvent('apricot_modalShow_start');

      activeElement && activeElement.dispatchEvent(event);

      elem.dispatchEvent(event);

      // animationend event is fired when a CSS Animation has completed
      elem.addEventListener('animationend', startAnimation, false);
    } else {
      calculateHeight();

      setFocusToFirstNode();

      scrollBehaviour('disable');

      data.onShow && data.onShow(elem);

      // dispatch modal show
      const event = new CustomEvent('apricot_modalShow');

      activeElement && activeElement.dispatchEvent(event);

      elem.dispatchEvent(event);
    }

    // trigger analytics tracker
    if (data.analytics) {
      trackAnalytics(true);
    }

    const body = document.getElementsByTagName('body')[0];

    Utils.addClass(body, 'cb-modal-open');
  };

  const removeEventListeners = () => {
    elem.modalPluginEvent = null;

    elem.removeEventListener('touchstart', modalOnClick, {
      passive: true,
    });

    elem.removeEventListener('click', modalOnClick);

    document.removeEventListener('keydown', modalOnKeyDown);

    window.removeEventListener('resize', windowOnResize);
  };

  const cleanUp = () => {
    const body = document.getElementsByTagName('body')[0];
    if (body) {
      body.style.overflowY = '';

      body.style.height = '';

      Utils.removeClass(body, 'cb-modal-open');
    }

    if (data.videoModal) {
      if (modalContainer) {
        modalContainer.style.overflowY = '';

        modalContainer.style.height = '';

        modalContainer.style.width = '';

        modalContainer.style.height = '';
      }
    } else {
      if (adjustableContent) {
        adjustableContent.style.overflowY = '';

        adjustableContent.style.height = '';
      }
    }
  };

  /**
   * Shows modal
   * @return {void}
   */
  const show = () => {
    showModal();
  };

  /**
   * Closes modal
   * @param {Object} options
   * @param  {Function} options.onClose [callback function to call when modal closes]
   * @return {void}
   */
  const close = (options = {}) => {
    closeModal(options);

    removeEventListeners();

    cleanUp();
  };

  /**
   * Adjust Height for active modal
   * @return {void}
   */
  const adjustHeight = () => {
    if (elem && Utils.hasClass(elem, 'cb-open')) {
      calculateHeight();
    }
  };

  const destroy = () => {
    if (elem.modalPlugin === 'cb') {
      elem.modalPlugin = null;

      removeEventListeners();

      cleanUp();

      if (Utils.elemExists(trigger)) {
        trigger.removeEventListener('click', showModal);
      }
    }
  };

  const init = () => {
    elem.modalPlugin = 'cb';

    modalComponents();

    // Add Events
    addEventListeners();
    if (Utils.elemExists(trigger)) {
      trigger.addEventListener('click', showModal);
    }
  };

  if (elem.modalPlugin !== 'cb' && !data.controlled) {
    init();
  } else {
    modalComponents();
  }

  return {
    show,
    close,
    adjustHeight,
    destroy,
  };
};

export default Modal;
