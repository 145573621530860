/* ========================================================================
 * Apricot's Event Timeline
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/event-timeline.scss';

// javaScript
import Utils from './CBUtils';

/**
 * Event Timeline
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @returns {{destroy: Function}}
 *
 */
const EventTimeline = (data = {}) => {
  const defaultData = {
    elem: null,
  };

  data = { ...defaultData, ...data };

  const elem = data.elem;
  if (!Utils.elemExists(elem)) return null;

  const a11y = () => {
    const items = elem.querySelectorAll('.cb-card');

    items.forEach(item => {
      const year = item.querySelector('.cb-event-year');
      let yearId = '';
      if (year) {
        yearId = Utils.attr(year, 'id') ? Utils.attr(year, 'id') : Utils.uniqueID(5, 'apricot_');

        Utils.attr(year, 'id', yearId);
      }

      const header = item.querySelector('.cb-card-header');
      let headerId = '';
      if (header) {
        headerId = Utils.attr(header, 'id') ? Utils.attr(header, 'id') : Utils.uniqueID(5, 'apricot_');

        Utils.attr(header, 'id', headerId);
      }

      const title = item.querySelector('.cb-card-title');
      let titleId = '';
      if (title) {
        titleId = Utils.attr(title, 'id') ? Utils.attr(title, 'id') : Utils.uniqueID(5, 'apricot_');

        Utils.attr(title, 'id', titleId);
      }

      const links = item.querySelectorAll('.cb-card-action .cb-btn');

      links.forEach(link => {
        Utils.attr(link, 'aria-describedby', `${yearId} ${headerId} ${titleId}`);
      });
    });
  };

  const destroy = () => {
    if (elem.eventTimelinePlugin === 'cb') {
      elem.eventTimelinePlugin = null;
    }
  };

  const init = () => {
    elem.eventTimelinePlugin = 'cb';

    if (Utils.hasClass(elem, 'cb-event-cards-horizontal')) {
      elem.querySelectorAll('.cb-card').forEach(card => {
        if (card.querySelector('.cb-card-label')) {
          Utils.addClass(card, 'cb-has-label');
        }
      });
    }

    a11y();
  };

  if (elem.eventTimelinePlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default EventTimeline;
