/* ========================================================================
 * Apricot's Pagination
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/pagination.scss';
import '../scss/includes/button.scss';

// javaScript
export default {};
