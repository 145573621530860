/* ========================================================================
 * Apricot's Illustrations
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/illustrations.scss';

// javaScript
export default {};
