/* ========================================================================
 * Apricot's Tabs
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/tab.scss';
import '../scss/includes/menu-list.scss';

// javaScript
import Utils from './CBUtils';

/**
 * Tabs
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.react
 * @param {Boolean} data.shadowRoot
 * @param {Function} data.callBack
 * @returns {{destroy: Function}}
 */

const Tabs = (data = {}) => {
  const defaultData = {
    elem: null,
    react: false,
    shadowRoot: false,
    callBack: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  const elem = data.elem;
  const callBack = data.callBack;
  // list of menu items
  let panels = [];

  if (!Utils.elemExists(elem)) return null;

  const getTabs = () => {
    return elem.getElementsByTagName('a');
  };

  const clickEventPrevent = e => {
    e.preventDefault();
  };
  const clickEvent = e => {
    e.preventDefault();
    const tab = e.currentTarget;

    // eslint-disable-next-line no-use-before-define
    toggle(tab);
  };
  const keyEvent = e => {
    const tab = e.currentTarget;
    const k = e.which || e.keyCode;

    // space
    if (k === 32) {
      e.preventDefault();

      // eslint-disable-next-line no-use-before-define
      toggle(tab);

      return;
    }

    //Not left/right
    if (!/(37|39)/.test(k)) {
      return;
    }

    let index = null;
    const items = elem.querySelectorAll('[role=tab]:not(.cb-disabled)');

    Array.prototype.forEach.call(items, (item, i) => {
      if (tab === item) {
        index = i;
      }
    });

    if (k === 37) index--; //left
    if (k === 39) index++; //right

    if (index < 0) index = items.length - 1;
    if (index === items.length) index = 0;

    const newActive = items.item(index);

    newActive.focus();
  };
  const resetEvents = tab => {
    tab.removeEventListener('click', clickEventPrevent);

    tab.removeEventListener('click', clickEvent);

    tab.removeEventListener('keydown', keyEvent);
  };

  const resetTabs = () => {
    const tabs = getTabs();

    Array.prototype.forEach.call(tabs, tab => {
      Utils.removeClass(tab, 'cb-selected');

      Utils.attr(tab, 'aria-selected', 'false');

      Utils.attr(tab, 'tabindex', '-1');
    });
  };

  const resetPanels = () => {
    Array.prototype.forEach.call(panels, panel => {
      Utils.removeClass(panel, 'cb-selected');

      Utils.attr(panel, 'tabindex', '-1');
    });
  };

  const toggle = tab => {
    // TBD: This is a short term fix
    // It's from react and controlled
    if (data.react) {
      if (!tab) return;
    } else {
      if (!tab || Utils.attr(tab, 'aria-selected') === 'true') return;
    }

    resetTabs();

    resetPanels();

    Utils.addClass(tab, 'cb-selected');

    Utils.attr(tab, 'aria-selected', 'true');

    Utils.attr(tab, 'tabindex', '0');
    const root = data.shadowRoot ? Utils.getShadowRoot(elem) : document;
    const panel = root.getElementById(Utils.attr(tab, 'aria-controls'));
    if (!Utils.elemExists(panel)) return;
    Utils.addClass(panel, 'cb-selected');

    Utils.attr(panel, 'tabindex', '0');

    const event = new CustomEvent('apricot_tabChanged');

    event.data = {
      id: Utils.attr(tab, 'aria-controls'),
    };

    elem.dispatchEvent(event);
    if (callBack) {
      callBack(tab);
    }
  };

  const a11y = tab => {
    const id = Utils.attr(tab, 'href').replace('#', '');
    const root = data.shadowRoot ? Utils.getShadowRoot(elem) : document;
    const panel = root.getElementById(id);
    const tabId = Utils.attr(tab, 'id') ? Utils.attr(tab, 'id') : Utils.uniqueID(5, 'apricot_');

    Utils.attr(tab, 'id', tabId);

    if (Utils.elemExists(panel)) {
      Utils.attr(tab, 'aria-controls', id);

      Utils.attr(panel, 'aria-labelledby', tabId);
    }

    if (Utils.hasClass(tab, 'cb-selected')) {
      Utils.attr(tab, 'aria-selected', true);

      Utils.attr(tab, 'tabindex', '0');

      // make content focusable
      Utils.attr(panel, 'tabindex', '0');
    } else {
      Utils.attr(tab, 'aria-selected', 'false');

      Utils.attr(tab, 'tabindex', '-1');

      Utils.attr(panel, 'tabindex', '-1');
    }

    if (Utils.hasClass(tab, 'cb-disabled')) {
      Utils.attr(tab, 'aria-disabled', true);
    }
  };

  const adjust = () => {
    const tabs = getTabs();

    panels = [];

    Array.prototype.forEach.call(tabs, tab => {
      const id = Utils.attr(tab, 'href').replace('#', '');
      const root = data.shadowRoot ? Utils.getShadowRoot(elem) : document;
      const panel = root.getElementById(id);
      if (Utils.elemExists(panel)) {
        panels.push(panel);
      }
      resetEvents(tab);

      Utils.attr(tab, 'tabindex', '-1');

      a11y(tab);

      if (Utils.attr(tab, 'aria-disabled') === 'true') {
        tab.addEventListener('click', clickEventPrevent);
      } else {
        tab.addEventListener('click', clickEvent);

        tab.addEventListener('keydown', keyEvent);
      }
    });
  };

  const adjustTabs = () => {
    setTimeout(() => {
      adjust();
    }, 50);
  };

  const destroy = () => {
    if (elem.tabsPlugin === 'cb') {
      elem.tabsPlugin = null;

      const tabs = getTabs();

      Array.prototype.forEach.call(tabs, tab => {
        resetEvents(tab);
      });
    }
  };

  const init = () => {
    elem.tabsPlugin = 'cb';

    adjust();
  };

  if (elem.tabsPlugin !== 'cb') {
    init();
  }

  return {
    adjustTabs: adjustTabs,
    destroy: destroy,
  };
};

export default Tabs;
