/* ========================================================================
 * Apricot's Breadcrumbs
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/breadcrumb.scss';

// javaScript
export default {};
