/* ========================================================================
 * Apricot's Logos
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/glyph-logos.scss';
import '../scss/includes/svg-logos.scss';

// javaScript
export default {};
