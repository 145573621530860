/* ========================================================================
 * Apricot's Sticky
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/sticky.scss';
import CBUtils from './CBUtils';

/**
 * Sticky Container
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Number} data.offsetTop
 * @param {Boolean} data.dropShadow
 * @param {Boolean} data.parent
 * @param {Element} data.localNavElem
 * @param {Function} data.callBack
 * @returns {{destroy: Function}}
 */
const Sticky = (data = {}) => {
  const defaultData = {
    elem: null,
    offsetTop: 0,
    dropShadow: true,
    parent: false,

    localNavElem: null,
    callBack: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let localNavElem = data.localNavElem;
  let callBack = data.callBack;

  let parentElem = null;
  let scrollHappened = false;
  let offsetTop = data.offsetTop;
  let offset = 0;
  let height = 0;
  // for delayed resize
  let resizeId = 0;

  if (!CBUtils.elemExists(elem)) return null;

  const stickAndPushFunction = () => {
    let bPos = 0;
    let docScroll = data.parent ? parentElem.scrollTop : document.body.scrollTop || document.documentElement.scrollTop;

    if (!scrollHappened) {
      // offsetTop = CBUtils.offset(elem) ? CBUtils.offset(elem).top : 0;
      height = CBUtils.outerHeight(localNavElem);
      if (data.parent) {
        offset = CBUtils.offsetToParent(elem) ? CBUtils.offsetToParent(elem).top : 0;
      } else {
        offset = CBUtils.offset(elem) ? CBUtils.offset(elem).top : 0;
      }
      scrollHappened = true;
    }

    if (docScroll >= offset - height) {
      bPos = offset - docScroll;
      if (bPos < 0) {
        if (!CBUtils.hasClass(elem, 'cb-sticky-active')) {
          CBUtils.addClass(elem, 'cb-sticky-active');

          data.dropShadow && CBUtils.addClass(elem, 'cb-box-shadow-bottom');

          elem.style.top = '0';

          localNavElem.style.top = `-${height + 8}px`;

          callBack && callBack(true);
        }
      } else {
        bPos = offset - docScroll;

        localNavElem.style.top = `${bPos - height}px`;
      }
    } else {
      if (CBUtils.hasClass(elem, 'cb-sticky-active')) {
        CBUtils.removeClass(elem, 'cb-sticky-active');

        data.dropShadow && CBUtils.removeClass(elem, 'cb-box-shadow-bottom');

        localNavElem.style.top = '0';

        callBack && callBack(false);
      }
    }
  };

  const adjustHeightVariables = () => {
    scrollHappened = false;

    stickAndPushFunction();
  };

  const delayedResize = () => {
    clearTimeout(resizeId);

    resizeId = setTimeout(adjustHeightVariables, 500);
  };

  const stickFunction = () => {
    let docScroll = parent ? parentElem.scrollTop : document.body.scrollTop || document.documentElement.scrollTop;
    if (!scrollHappened) {
      if (parent) {
        offset = CBUtils.offsetToParent(elem) ? CBUtils.offsetToParent(elem).top : 0;
      } else {
        offset = CBUtils.offset(elem) ? CBUtils.offset(elem).top : 0;
      }
      scrollHappened = true;
    }

    if (docScroll > offset - offsetTop) {
      if (!CBUtils.hasClass(elem, 'cb-sticky-active')) {
        CBUtils.addClass(elem, 'cb-sticky-active');

        data.dropShadow && CBUtils.addClass(elem, 'cb-box-shadow-bottom');

        elem.style.top = `${offsetTop}px`;

        callBack && callBack(true);
      }
    } else {
      if (CBUtils.hasClass(elem, 'cb-sticky-active')) {
        elem.style.top = null;

        CBUtils.removeClass(elem, 'cb-sticky-active');

        data.dropShadow && CBUtils.removeClass(elem, 'cb-box-shadow-bottom');

        callBack && callBack(false);
      }
    }
  };

  const destroy = () => {
    if (elem.stickyPlugin === 'cb') {
      elem.stickyPlugin = null;
      if (CBUtils.elemExists(localNavElem)) {
        document.removeEventListener('apricot_breakpointChange', adjustHeightVariables);

        parentElem.removeEventListener('scroll', stickAndPushFunction);
      } else {
        parentElem.removeEventListener('scroll', stickFunction);
      }
    }
  };

  const init = () => {
    elem.stickyPlugin = 'cb';

    if (data.parent) {
      parentElem = CBUtils.parent(elem);
    } else {
      parentElem = window;
    }

    // stick container and push up local Navigation
    if (CBUtils.elemExists(localNavElem)) {
      window.addEventListener('resize', delayedResize);

      parentElem.addEventListener('scroll', stickAndPushFunction);
    } else {
      parentElem.addEventListener('scroll', stickFunction);
    }
  };

  if (elem.stickyPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default Sticky;
