/* ========================================================================
 * Apricot's Dropdown
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/dropdown.scss';

// javaScript
import Utils from './CBUtils';

/**
 * Dropdown
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.selectList
 * @param {Boolean} data.selectDropdown
 * @param {Boolean} data.selectFirstValue
 * @param {String} data.defaultValue
 * @param {Boolean} data.filter
 * @param {Boolean} data.caseSensitive
 * @param {Boolean} data.closeOnClick
 * @param {Boolean} data.closeOnClickOutside
 * @param {Function} data.callBack
 * @returns {{reset: Function}}
 * @returns {{destroy: Function}}
 * @returns {{setValue: Function}}
 * @returns {{keyBoardReset: Function}}
 * @returns {{selectDropdownInit: Function}}
 *
 */
const Dropdown = (data = {}) => {
  const defaultData = {
    elem: null,
    selectList: false,
    selectDropdown: false,
    selectFirstValue: true,
    defaultValue: null,
    filter: false,
    caseSensitive: false,
    closeOnClick: false,
    closeOnClickOutside: true,
    callBack: null,
    mode: 1,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let select = null;
  let selectValueElem = null;
  let toggle = null;
  let menu = null;
  let openIcon = null;
  let closeIcon = null;
  let filter = null;
  let selectListTitle = null;

  if (!Utils.elemExists(elem)) return null;

  const SetInnerHtmlSelectValueElem = value => {
    if (selectValueElem) {
      selectValueElem.innerHTML = value;
    }
  };
  const selectDropdownElem = () => {
    if (!Utils.hasClass(elem, 'cb-select')) return;
    select = elem;

    elem = select.querySelector('.cb-dropdown-select');

    selectValueElem = select.querySelector('.cb-select-dropdown-value');
  };

  const resetSelected = () => {
    Array.prototype.forEach.call(elem.querySelectorAll('a[role="menuitem"], a[role="option"]'), link => {
      Utils.removeClass(link, 'cb-selected');

      Utils.removeAttr(link, 'aria-current');

      // for select Dropdown
      Utils.removeAttr(link, 'aria-selected');
    });
  };

  const getFocusableNodes = selected => {
    const list = [];

    menu &&
      menu.querySelectorAll(selected ? '.cb-selected' : Utils.FOCUSABLE_ELEMENTS).forEach(node => {
        if (
          Utils.attr(node, 'aria-disabled') !== 'true' &&
          Utils.attr(node, 'aria-hidden') !== 'true' &&
          Utils.attr(node, 'tabindex') !== '-1'
        ) {
          list.push(node);
        }
      });

    return list;
  };

  const adjustPosition = () => {
    const left = menu.getBoundingClientRect().left;
    const mWidth = menu.offsetWidth;
    const wWidth = Utils.windowsDimension().width;
    const limit = parseInt(left + mWidth);

    if (limit > wWidth) {
      Utils.addClass(menu, 'cb-menu-right');
    } else {
      Utils.removeClass(menu, 'cb-menu-right');
    }
  };

  const resetHeightAdjustment = () => {
    menu.style.height = 'auto';

    Utils.removeClass(menu, 'cb-dropdown-menu-scroll');
  };

  const adjustDropdownHeight = () => {
    const top = menu.getBoundingClientRect().top;
    const mHeight = menu.offsetHeight;
    const wHeight = Utils.windowsDimension().height;

    const limit = parseInt(wHeight - top);

    if (mHeight > limit) {
      Utils.addClass(menu, 'cb-dropdown-menu-scroll');

      menu.style.height = parseInt(limit - 48) + 'px';
    } else {
      resetHeightAdjustment();
    }
  };

  const toggleDropdown = e => {
    e && e.preventDefault();

    // ignore toggle
    if (Utils.attr(toggle, 'aria-disabled') === 'true' || Utils.attr(toggle, 'aria-hidden') === 'true') return;

    // is open -> close
    if (Utils.hasClass(elem, 'cb-open')) {
      Utils.removeClass(elem, 'cb-open');

      Utils.removeClass(openIcon, 'cb-hidden');

      Utils.addClass(closeIcon, 'cb-hidden');

      Utils.attr(toggle, 'aria-expanded', 'false');

      resetHeightAdjustment();

      if (data.selectDropdown) {
        elem.querySelectorAll('button')[0].focus();
      } else {
        elem.querySelectorAll('a')[0].focus();
      }
      const event1 = new CustomEvent('apricot_dropdownClose');

      elem.dispatchEvent(event1);
    } else {
      Utils.addClass(elem, 'cb-open');

      Utils.removeClass(closeIcon, 'cb-hidden');

      Utils.addClass(openIcon, 'cb-hidden');

      Utils.attr(toggle, 'aria-expanded', 'true');

      // check if we have any selected item in the menu
      let nodes = getFocusableNodes(true);

      if (nodes.length <= 0) {
        nodes = getFocusableNodes();
      }
      adjustDropdownHeight();

      adjustPosition();

      if (nodes.length > 0) {
        setTimeout(() => {
          nodes[0].focus();
        }, 50);
      }

      const event2 = new CustomEvent('apricot_dropdownOpen');

      elem.dispatchEvent(event2);
    }
  };

  const closeA11Y = e => {
    if (e.type === 'click') {
      if (!Utils.hasClass(elem, 'cb-open') || elem.contains(e.target)) {
        return;
      }
      toggleDropdown();
    } else if (e.keyCode === 27) {
      if (!Utils.hasClass(elem, 'cb-open')) {
        return;
      }
      toggleDropdown();
    }
  };

  const keydownEvent = e => {
    const node = e.currentTarget;
    const k = e.which || e.keyCode;

    if (k !== 9 && k !== 40 && k !== 38 && k !== 16) {
      return;
    }

    if (data.selectDropdown && (k === 9 || e.shiftKey)) {
      e.preventDefault();

      // close on Tab
      if (k === 9) {
        const event = new KeyboardEvent('keydown', { keyCode: 27 });

        closeA11Y(event);
      }

      return;
    }

    let index = 0;
    const tabbingBack = e.shiftKey;
    const items = getFocusableNodes();

    Array.prototype.forEach.call(items, (item, i) => {
      if (node === item) {
        index = i;
      }
    });

    //make sure menus are closed after tab away
    if (k === 9 && ((k === 9 && tabbingBack && index === 0) || (!tabbingBack && index === items.length - 1))) {
      if (k === 9 && tabbingBack && index === 0) {
        e.preventDefault();

        e.stopPropagation();
      }

      const event = new CustomEvent('apricot_dropdownKeyboardToggle');
      let obj = {};
      obj.tab = k === 9;

      obj.shiftTab = tabbingBack;

      event.data = obj;

      elem.dispatchEvent(event);

      toggleDropdown();
    } else {
      //up/down arrows
      e.preventDefault();

      e.stopPropagation();

      if (k === 38 || (k === 9 && tabbingBack)) {
        index--; //up|shift+tab
      } else if (k === 40 || k === 9) {
        index++; //down|tab
      }

      if (index < 0 || index === items.length) {
        return;
      }

      const newActive = items[index];

      newActive.focus();
    }
  };
  const keyBoardNavigation = () => {
    Array.prototype.forEach.call(getFocusableNodes(), node => {
      node.addEventListener('keydown', keydownEvent);
    });
  };

  const A11yEvents = () => {
    menu &&
      menu.querySelectorAll('a').forEach(node => {
        if (Utils.attr(node, 'aria-disabled') === 'true' || Utils.attr(node, 'aria-hidden') === 'true') {
          node.addEventListener('click', e => {
            e.preventDefault();
          });
        }
      });

    keyBoardNavigation();
  };

  const closeOnClick = () => {
    Array.prototype.forEach.call(
      elem.querySelectorAll('a[role="menuitem"], a[role="option"],  a[data-cb-link="true"]:not(.cb-dropdown-toggle)'),
      link => {
        link.addEventListener('click', e => {
          e.preventDefault();

          if (!Utils.hasClass(elem, 'cb-open')) {
            return;
          }
          toggleDropdown();
        });
      },
    );
  };

  const selectDropdownClickEvent = e => {
    e.preventDefault();
    const link = e.currentTarget;

    const parent = Utils.parent(link);
    if (!parent.classList.contains('cb-disabled')) {
      const theValue = Utils.attr(link, 'data-value');
      const theLabel = link.innerText;

      SetInnerHtmlSelectValueElem(theLabel);

      toggle && toggle.setAttribute('data-value', theValue);

      const event = new CustomEvent('change', {
        detail: { value: theValue, label: theLabel },
      });

      select && select.dispatchEvent(event);

      data.callBack && data.callBack(theValue, theLabel);

      resetSelected();

      Utils.addClass(link, 'cb-selected');

      Utils.attr(link, 'aria-selected', 'true');
    }
  };
  const selectDropdownInit = () => {
    selectDropdownElem();

    // we have to make sure there is no old events attached to link
    // mainly from previous init in react
    Array.prototype.forEach.call(elem.querySelectorAll('a[role="option"]'), link => {
      link.removeEventListener('click', selectDropdownClickEvent);

      link.addEventListener('click', selectDropdownClickEvent);
    });

    // select first item
    if (data.selectFirstValue && !data.defaultValue) {
      // by default, select the first item
      const firstItem = elem.querySelectorAll('a[role="option"]')[0];

      SetInnerHtmlSelectValueElem(firstItem ? firstItem.innerText : '');

      let theValue = Utils.attr(firstItem, 'data-value');
      toggle && toggle.setAttribute('data-value', theValue);

      Array.prototype.forEach.call(elem.querySelectorAll('a[role="option"]'), link => {
        // if an item is selected ignore the first item
        if (Utils.hasClass(link, 'cb-selected')) {
          SetInnerHtmlSelectValueElem(link.innerText);

          theValue = Utils.attr(link, 'data-value');

          toggle && toggle.setAttribute('data-value', theValue);

          data.selectDropdown && Utils.attr(link, 'aria-selected', 'true');
        } else {
          data.selectDropdown && Utils.attr(link, 'aria-selected', 'false');
        }
      });
    } else if (data.defaultValue) {
      // select default value
      const defaultItem = elem.querySelector(`a[data-value="${data.defaultValue}"]`);

      SetInnerHtmlSelectValueElem(defaultItem ? defaultItem.innerText : '');

      toggle && toggle.setAttribute('data-value', data.defaultValue);

      defaultItem && Utils.addClass(defaultItem, 'cb-selected');

      Array.prototype.forEach.call(elem.querySelectorAll('a[role="option"]'), link => {
        if (Utils.hasClass(link, 'cb-selected')) {
          data.selectDropdown && Utils.attr(link, 'aria-selected', 'true');
        } else {
          data.selectDropdown && Utils.attr(link, 'aria-selected', 'false');
        }
      });
    }

    A11yEvents();

    // select always closes onClick
    closeOnClick();
  };

  const cleanValue = value => {
    let str = value;
    if (!data.caseSensitive) {
      str = value.toUpperCase();
    }

    return str;
  };

  const filterDropdown = () => {
    const value = cleanValue(filter.value);

    Array.prototype.forEach.call(menu.querySelectorAll('a'), node => {
      const txtValue = node.textContent || node.innerText;
      const parent = Utils.parent(node);

      if (cleanValue(txtValue).indexOf(value) > -1) {
        Utils.removeClass(parent, 'cb-hidden');

        Utils.attr(node, 'tabIndex', '0');

        Utils.removeAttr(node, 'aria-hidden');
      } else {
        Utils.addClass(parent, 'cb-hidden');

        Utils.attr(node, 'tabIndex', '-1');

        Utils.attr(node, 'aria-hidden', 'true');
      }
    });
  };

  const openDropdown = e => {
    e.preventDefault();

    e.stopPropagation();

    if (e.keyCode === 32 || e.keyCode === 40) {
      if (!Utils.hasClass(elem, 'cb-open')) {
        toggleDropdown(e);
      }
    }
  };

  const closeOnClickOutside = () => {
    document.addEventListener('keydown', closeA11Y, true);

    document.addEventListener('click', closeA11Y, true);
  };

  const callBackEvent = () => {
    Array.prototype.forEach.call(elem.querySelectorAll('a[role="menuitem"], a[role="option"]'), link => {
      link.addEventListener('click', e => {
        e.preventDefault();

        data.callBack(link);
      });
    });
  };

  const keyBoardReset = () => {
    Array.prototype.forEach.call(getFocusableNodes(), node => {
      node.removeEventListener('keydown', keydownEvent);
    });

    Array.prototype.forEach.call(getFocusableNodes(), node => {
      node.addEventListener('keydown', keydownEvent);
    });
  };

  const setValue = value => {
    // select default value
    const defaultItem = elem.querySelector(`a[data-value="${value}"]`);
    const theLabel = defaultItem ? defaultItem.innerText : '';

    SetInnerHtmlSelectValueElem(theLabel);

    toggle && toggle.setAttribute('data-value', value);

    Array.prototype.forEach.call(elem.querySelectorAll('a[role="option"]'), link => {
      Utils.removeClass(link, 'cb-selected');
    });

    // set new active item
    Utils.addClass(defaultItem, 'cb-selected');

    // set a11y
    Array.prototype.forEach.call(elem.querySelectorAll('a[role="option"]'), link => {
      if (Utils.hasClass(link, 'cb-selected')) {
        data.selectDropdown && Utils.attr(link, 'aria-selected', 'true');
      } else {
        data.selectDropdown && Utils.attr(link, 'aria-selected', 'false');
      }
    });
  };
  const reset = async () => {
    // data.selectDropdown && await selectDropdownElem();
    if (toggle && Utils.elemExists(toggle.firstChild) && data.selectList) {
      resetSelected();
      if (toggle.querySelector('span:not(.sr-only)')) {
        toggle.querySelector('span:not(.sr-only)').innerHTML = selectListTitle ? selectListTitle : '';
      }
      if (toggle.querySelector('span.sr-only')) {
        toggle.querySelector('span.sr-only').innerHTML = '';
      }
      toggle && toggle.focus();
    } else if (data.selectDropdown) {
      resetSelected();

      toggle && toggle.setAttribute('data-value', '');

      SetInnerHtmlSelectValueElem('');

      toggle && toggle.focus();
    }
  };

  const destroy = () => {
    if (elem.dropdownPlugin === 'cb') {
      elem.dropdownPlugin = null;

      keyBoardReset();

      toggle && toggle.removeEventListener('click', toggleDropdown);

      toggle && toggle.removeEventListener('keyup', openDropdown);
      if (data.closeOnClickOutside) {
        document.removeEventListener('keydown', closeA11Y, true);

        document.removeEventListener('click', closeA11Y, true);
      }
      if (filter) {
        filter.removeEventListener('keyup', filterDropdown);

        filter.removeEventListener('change', filterDropdown);
      }
    }
  };

  const init = async () => {
    elem.dropdownPlugin = 'cb';

    toggle = elem.querySelector('.cb-dropdown-toggle');

    menu = elem.querySelector('.cb-dropdown-menu');

    if (!Utils.elemExists(menu)) return null;

    // setup select dropdown
    if (data.selectDropdown) {
      selectDropdownInit();
      if (!Utils.elemExists(select)) return null;
      if (Utils.hasClass(select, 'cb-disabled')) {
        Utils.attr(toggle, 'aria-disabled', 'true');

        Utils.attr(toggle, 'tabindex', '-1');
      }
    } else if (Utils.hasClass(elem, 'cb-disabled')) {
      // all other combination
      Utils.attr(toggle, 'aria-disabled', 'true');

      Utils.attr(toggle, 'tabindex', '-1');
    }

    if (data.filter) {
      filter = elem.querySelector('input');
      const menuID = Utils.attr(menu, 'id') ? Utils.attr(menu, 'id') : Utils.uniqueID(5, 'apricot_');
      const toggleID = Utils.attr(toggle, 'id') ? Utils.attr(toggle, 'id') : Utils.uniqueID(5, 'apricot_');

      Utils.attr(menu, 'role', 'region');

      Utils.attr(menu, 'id', menuID);

      Utils.attr(menu, 'aria-labelledby', toggleID);

      Utils.attr(toggle, 'aria-controls', menuID);

      Utils.removeAttr(toggle, 'aria-haspopup');
    }

    if (data.selectList) {
      // keep main title
      selectListTitle = toggle.querySelector('span:not(.sr-only)').innerHTML;

      Array.prototype.forEach.call(elem.querySelectorAll('a[role="menuitem"], a[role="option"]'), link => {
        link.addEventListener('click', e => {
          e.preventDefault();

          let dropdownContainer = Utils.getClosest(link, '.cb-dropdown-select');
          if (toggle.querySelector('span.sr-only') && toggle.querySelector('.sr-only').innerHTML === '') {
            toggle.querySelector('.sr-only').innerHTML = selectListTitle;
          }

          const parent = Utils.parent(link);
          if (!parent.classList.contains('cb-disabled')) {
            dropdownContainer.querySelector('span:not(.sr-only)').innerHTML = link.innerText;
          }

          resetSelected();

          Utils.addClass(link, 'cb-selected');

          Utils.attr(link, 'aria-current', 'true');
        });
      });
    }

    const iconsUp = toggle.querySelector('.cb-icon.cb-down');
    const iconsDown = toggle.querySelector('.cb-icon.cb-up');

    iconsUp && Utils.addClass(iconsUp, 'cb-dropdown-arrow');

    iconsDown && Utils.addClass(iconsDown, 'cb-dropdown-arrow');

    const icons = toggle.querySelectorAll('.cb-dropdown-arrow');
    if (icons[0]) {
      openIcon = icons[0];
      if (icons[1]) {
        closeIcon = icons[1];
      }
    }

    toggle.addEventListener('click', toggleDropdown);

    toggle.addEventListener('keyup', openDropdown);

    !data.selectDropdown && A11yEvents();

    if (data.closeOnClickOutside) {
      closeOnClickOutside();
    }

    if (Utils.elemExists(filter)) {
      filter.addEventListener('keyup', filterDropdown);

      filter.addEventListener('change', filterDropdown);
    }

    if (!data.selectDropdown && data.closeOnClick) {
      closeOnClick();
    }
    if (!data.selectDropdown && data.callBack) {
      callBackEvent();
    }
  };

  if (elem.dropdownPlugin !== 'cb') {
    init();
  }

  return {
    reset: reset,
    destroy: destroy,
    setValue: setValue,
    keyBoardReset: keyBoardReset,
    selectDropdownInit: selectDropdownInit,
  };
};

export default Dropdown;
