/* ========================================================================
 * Apricot's Photo Gallery
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/photo-gallery.scss';

// javaScript
import CBModal from './CBModal';
import Utils from './CBUtils';

/**
 * Photo Gallery
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.modal
 * @param {Number} data.galleryRange1
 * @param {Number} data.galleryRange2
 * @param {Number} data.galleryRange3
 * @param {Number} data.galleryRange4
 * @param {Number} data.galleryRange5
 * @param {Number} data.autoPlayTime
 * @param {String} data.altTxt
 * @returns {{destroy: Function}}
 */
const PhotoGallery = (data = {}) => {
  const defaultData = {
    elem: null,
    modal: null,
    galleryRange1: 1146,
    galleryRange2: 1048,
    galleryRange3: 754,
    galleryRange4: 656,
    galleryRange5: 290,

    autoPlayTime: 2000,
    altTxt: 'Photo Gallery',
  };

  let elem = data.elem;
  if (!Utils.elemExists(elem)) return false;

  let modal = data.modal;
  if (!Utils.elemExists(modal)) return false;

  data = { ...defaultData, ...data };

  let elemId = Utils.attr(elem, 'id') ? Utils.attr(elem, 'id') : Utils.uniqueID(5, 'apricot_');
  let modalId = Utils.attr(modal, 'id') ? Utils.attr(modal, 'id') : Utils.uniqueID(5, 'apricot_');
  let imgId = Utils.uniqueID(5, 'apricot_');

  let hasDesc = false;
  let singleImg = false;
  let isPlaying = false;
  let layoutType = '';

  let viewport = 0;
  let autoPlayTimeOut = 0;
  let tabList = [];
  let plugin = {};

  let $ul = null;
  let $close = null;
  let $play = null;
  let $fullscreen = null;
  let $next = null;
  let $prev = null;
  let $des = null;
  let $img = null;

  let ModalIns = null;

  const breakpointChange = e => {
    const breakpointData = e.data;

    viewport = breakpointData.prefix;
  };

  const thumbnailLayoutClass = () => {
    const cWidth = parseInt(Utils.width(elem), 10);

    layoutType = '';

    if (cWidth >= data.galleryRange2) {
      //1146 - 1048: 5
      layoutType = 'cb-photo-gallery-5';
    } else if (cWidth <= data.galleryRange2 - 1 && cWidth >= data.galleryRange3) {
      //1047 - 754: 4
      layoutType = 'cb-photo-gallery-4';
    } else if (cWidth <= data.galleryRange3 - 1 && cWidth >= data.galleryRange4) {
      //753 - 656: 3
      layoutType = 'cb-photo-gallery-3';
    } else if (cWidth <= data.galleryRange4 - 1 && cWidth >= data.galleryRange5) {
      //655 - 290: 2
      layoutType = 'cb-photo-gallery-2';
    } else if (cWidth < data.galleryRange5) {
      //290: 1
      layoutType = 'cb-photo-gallery-1';
    }

    if ($ul && !Utils.isBlank(layoutType)) {
      Utils.removeAttr($ul, 'class');

      Utils.addClass($ul, layoutType);
    }
  };

  const calculateAspectRatioFit = img => {
    const $container = modal.querySelector('.cb-gallery-content');

    Utils.removeClass($container, 'cb-image-padding');

    const maxWidth = viewport === 'xs' || viewport === 'sm' ? Utils.width($img) : Utils.width($container) * (2 / 3);
    const maxHeight =
      viewport === 'xs' || viewport === 'sm' ? Utils.windowsDimension().height - 71 : Utils.height($img);
    const width = Utils.attr(img, 'data-cb-width');
    const height = Utils.attr(img, 'data-cb-height');
    let newW = 0;
    let newH = 0;
    let ratio = 0;

    if (width > height) {
      newW = maxWidth;

      ratio = height / width;

      newH = ratio * newW;
    } else {
      newH = maxHeight;

      ratio = width / height;

      newW = ratio * newH;
    }

    if (newH > maxHeight) {
      while (newH > maxHeight) {
        newH--;

        newW = 0;
      }
    }
    if (newW > maxWidth) {
      while (newW > maxWidth) {
        newW--;

        newH = 0;
      }
    }

    if (maxHeight - newH > 2) {
      Utils.addClass($container, 'cb-image-padding');
    }

    if (isNaN(newW) || newW === 0) {
      newW = 'auto';
    } else {
      newW = `${newW}px`;
    }

    if (isNaN(newH) || newH === 0) {
      newH = 'auto';
    } else {
      newH = `${newH}px`;
    }

    return { width: newW, height: newH };
  };

  const adjustImgSize = () => {
    const img = $img.querySelector('img');
    const $parent = modal.querySelector('.cb-gallery-image');

    if (img) {
      const sizeObj = calculateAspectRatioFit(img);

      img.style.width = sizeObj.width;

      img.style.height = sizeObj.height;

      if (viewport === 'xs' || viewport === 'sm') {
        $parent.style.width = null;
      } else {
        $parent.style.width = sizeObj.width;
      }
    }
  };

  const gallerySizeAdjustment = () => {
    if ($ul && !Utils.hasClass($ul, 'cb-photo-gallery-grid')) {
      thumbnailLayoutClass();
    }

    if (Utils.hasClass(modal, 'cb-open')) {
      adjustImgSize();
    }
  };

  const retrieveDataset = () => {
    let items = [];
    let obj = {};

    elem.querySelectorAll('.cb-photo-gallery > ul > li').forEach((li, index) => {
      const $thumbImg = li.querySelector('.cb-photo-thumb');
      const $thumbInfo = li.querySelector('.cb-photo-thumb-info');
      const $detailsInfo = li.querySelector('.cb-photo-details-info');

      let img = '';
      let alt = '';
      let title = '';
      let $title = {};
      let subTitleArr = [];
      let detailsObj = {};

      Utils.attr(li, 'data-cb-thumb', `${index}`);

      obj = {};

      // large image
      img = Utils.attr($thumbImg, 'data-cb-img');

      alt = Utils.attr($thumbImg.querySelector('img'), 'alt')
        ? Utils.attr($thumbImg.querySelector('img'), 'alt')
        : data.altTxt;

      if ($thumbInfo) {
        if ($thumbInfo.hasChildNodes()) {
          hasDesc = true;
        }

        // main: title
        $title = $thumbInfo.querySelectorAll('h2, h3, h4, h5, h6');
        if ($title.length > 0) {
          title = $title[0].innerHTML;
        }

        // main: sub titles
        $thumbInfo.querySelectorAll('p').forEach(item => {
          subTitleArr.push(item.innerHTML);
        });
      }

      detailsObj = {};
      if ($detailsInfo) {
        detailsObj = $detailsInfo.cloneNode(true);

        Utils.removeClass(detailsObj, 'cb-photo-details-info');

        if ($detailsInfo.hasChildNodes()) {
          hasDesc = true;
        }
      }

      obj.img = img;

      obj.alt = alt;

      obj.title = title;

      obj.titleTag = $title[0] ? $title[0].tagName.toLowerCase() : '';

      obj.subTitleArr = subTitleArr;

      obj.details = detailsObj;

      items.push(obj);
    });

    if (items.length === 1) {
      singleImg = true;
    }

    plugin.items = items;

    plugin.count = elem.querySelectorAll('.cb-photo-gallery > ul > li').length;
  };

  const setupGalleryModal = () => {
    if (singleImg) {
      Utils.attr($prev, 'tabIndex', '-1');

      Utils.attr($prev, 'aria-hidden', 'true');

      Utils.addClass($prev, 'cb-hidden');

      Utils.attr($next, 'tabIndex', '-1');

      Utils.attr($next, 'aria-hidden', 'true');

      Utils.addClass($next, 'cb-hidden');

      Utils.addClass(modal, 'cb-single-img');
    }

    if (!hasDesc) {
      Utils.addClass(modal, 'cb-no-desc');
    }
  };

  const stopClickEvent = () => {
    clearTimeout(autoPlayTimeOut);
  };

  const stopAutoPlay = () => {
    if ($play) {
      const span = $play.querySelector('.cb-icon');

      Utils.removeClass(span, 'cb-pause-circle');

      Utils.addClass(span, 'cb-play-circle');

      isPlaying = false;

      Utils.attr($play, 'aria-pressed', 'false');

      stopClickEvent();
    }
  };

  const exitFullscreen = () => {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } catch (error) {
      console.warn(error);
    }
  };

  // mode: 1, previous
  // mode: 0, next
  const getItemsNumber = (mode, current) => {
    let number = 0;
    const count = parseInt(plugin.count, 10);

    current = parseInt(current, 10);

    if (mode) {
      // previous
      number = current > 0 ? current - 1 : count - 1;
    } else {
      // next
      number = current < count - 1 ? current + 1 : 0;
    }

    return number;
  };

  const activateFullscreen = () => {
    if ($img.requestFullscreen) {
      $img.requestFullscreen();
    } else if ($img.mozRequestFullScreen) {
      $img.mozRequestFullScreen();
    } else if ($img.webkitRequestFullscreen) {
      $img.webkitRequestFullscreen();
    } else if ($img.msRequestFullscreen) {
      $img.msRequestFullscreen();
    }
  };
  const fullBtnClickEvent = e => {
    e.preventDefault();

    if (Utils.hasClass(modal, 'cb-photo-full')) {
      exitFullscreen();
    } else {
      activateFullscreen();
    }
  };

  const getImgDimension = (src, alt) => {
    const $parent = modal.querySelector('.cb-gallery-image');

    const img = document.createElement('IMG');
    let imgW = 0;
    let imgH = 0;

    Utils.attr(img, 'src', src);

    Utils.attr(img, 'alt', alt);

    Utils.attr(img, 'id', imgId);

    img.addEventListener('load', () => {
      imgW = img.width;

      imgH = img.height;

      // these are the base values
      Utils.attr(img, 'data-cb-width', imgW);

      Utils.attr(img, 'data-cb-height', imgH);

      const sizeObj = calculateAspectRatioFit(img);

      img.style.width = sizeObj.width;

      img.style.height = sizeObj.height;

      if (viewport === 'xs' || viewport === 'sm') {
        $parent.style.width = null;
      } else {
        $parent.style.width = sizeObj.width;
      }

      if ($img.querySelectorAll('img').length <= 0) {
        Utils.append($img, img);
        if (Utils.hasClass($img, 'cb-has-fullscreen')) {
          img.addEventListener('click', fullBtnClickEvent);
        }
      }
    });
  };

  // update modal
  const setGalleryData = (galleryData, current) => {
    const $container = modal.querySelector('.cb-gallery-content');

    Utils.removeClass($container, 'cb-no-info');

    // hide current one
    Utils.removeClass($img, 'cb-active-effect');

    Utils.addClass($img, 'cb-hide-effect');

    const previous = getItemsNumber(1, current);
    const next = getItemsNumber(0, current);

    const $description = modal.querySelector('.cb-gallery-des');

    $description.innerHTML = '';

    Utils.remove($img.querySelector('img'));

    Utils.remove($img.querySelector('.sr-only'));

    const count = parseInt(plugin.count, 10);

    current = parseInt(current, 10) + 1;

    modal.querySelectorAll('.cb-count-num').forEach($count => {
      $count.innerText = count;
    });

    modal.querySelectorAll('.cb-current-num').forEach($current => {
      $current.innerText = current;
    });

    Utils.attr($next, 'data-cb-item', `${next}`);

    Utils.attr($next, 'aria-label', `next gallery image, show image ${parseInt(next + 1, 10)} of ${count}`);

    Utils.attr($prev, 'data-cb-item', `${previous}`);

    Utils.attr($prev, 'aria-label', `previous gallery image, show image ${parseInt(previous + 1, 10)} of ${count}`);

    if (galleryData && !!galleryData.img) {
      Utils.attr($img, 'data-cb-thumb', current);

      getImgDimension(galleryData.img, galleryData.alt);

      setTimeout(() => {
        Utils.removeClass($img, 'cb-hide-effect');

        Utils.addClass($img, 'cb-active-effect');
      }, 200);
    }

    const $span = document.createElement('SPAN');

    Utils.addClass($span, 'sr-only');

    $span.innerText = `Gallery Image ${current} of ${count}, ${galleryData.alt}`;

    Utils.append($img, $span);

    // Add label to role: dialog
    const modalContainer = modal.querySelector('.cb-modal-container');

    Utils.attr(modalContainer, 'aria-label', `Gallery Image ${current} of ${count}`);

    // set header tag based on thumb section
    let hasInfo = false;
    if (galleryData.titleTag !== '') {
      const $h = document.createElement(galleryData.titleTag.toUpperCase());

      Utils.addClass($h, 'cb-main-title');

      $h.innerText = galleryData.title;

      Utils.append($description, $h);

      hasInfo = true;
    }

    for (var st in galleryData.subTitleArr) {
      const $p = document.createElement('P');

      Utils.addClass($p, 'cb-main-info');

      $p.innerText = galleryData.subTitleArr[st];

      Utils.append($description, $p);

      hasInfo = true;
    }

    if (galleryData.details.nodeType == 1) {
      Utils.append($description, galleryData.details);

      hasInfo = true;
    }

    const $info = modal.querySelector('.cb-info-container');
    if ($info) {
      if (!hasInfo) {
        Utils.addClass($info, 'cb-no-info');

        Utils.addClass($container, 'cb-no-info');
      } else {
        Utils.removeClass($info, 'cb-no-info');
      }
    }

    if (!Utils.hasClass(modal, 'cb-open')) {
      // open modal
      modal.addEventListener('apricot_modalShow', () => {
        // user may not want a close button in the modal
        if ($close) {
          $close.focus();
        }
      });

      // close modal
      // set focus back to active image
      modal.addEventListener('apricot_modalClose', () => {
        const currentImage = Utils.attr(modal.querySelector('.cb-gallery-image-container'), 'data-cb-thumb');
        const focusNum = currentImage > 0 ? currentImage - 1 : 0;
        const $li = elem.querySelector(`[data-cb-thumb="${focusNum}"]`);

        const customEvent = new CustomEvent('apricot_photoGallery_close');

        elem.dispatchEvent(customEvent);

        const $a = $li.querySelectorAll('a')[0];

        if ($a) {
          Utils.addClass($a, 'cb-focus');

          $a.focus();
        }

        stopClickEvent();

        if (Utils.hasClass(modal, 'cb-photo-full')) {
          exitFullscreen();
        }
      });

      ModalIns = CBModal({ elem: modalId }).show();

      document.getElementById(modalId).addEventListener('apricot_modalClose', () => {
        stopAutoPlay();
      });
    }
  };

  const prevClickEvent = e => {
    // Stop playing
    if (isPlaying) {
      stopAutoPlay();
    }
    const $prevPlay = e.currentTarget;
    const selected = Utils.attr($prevPlay, 'data-cb-item');

    data = plugin.items[selected];
    const previous = getItemsNumber(1, selected);

    Utils.attr($prevPlay, 'data-cb-item', `${previous}`);

    setGalleryData(data, selected);
  };

  const thumbnailEventsClick = (e, li) => {
    e.stopPropagation();

    e.preventDefault();

    if (!plugin.items) return;

    const current = Utils.attr(li, 'data-cb-thumb');
    const currentData = plugin.items[current];

    setGalleryData(currentData, current);
  };

  const thumbnailEventsKeyBoard = (e, li) => {
    if (Utils.whichKey(e) === 'ENTER' || Utils.whichKey(e) === 'SPACE') {
      e.stopPropagation();

      e.preventDefault();

      if (!plugin.items) return;

      const current = Utils.attr(li, 'data-cb-thumb');
      const currentData = plugin.items[current];

      setGalleryData(currentData, current);
    }
  };

  const thumbnailEvents = () => {
    elem.querySelectorAll('.cb-photo-gallery > ul > li').forEach(li => {
      li.addEventListener('click', e => thumbnailEventsClick(e, li));

      // accessibility, enter/space
      li.addEventListener('keydown', e => thumbnailEventsKeyBoard(e, li));

      const $a = li.querySelector('a');
      if ($a) {
        // $a.addEventListener("click", (e) => {e.preventDefault(); e.stopPropagation();});

        $a.addEventListener('blur', () => {
          Utils.removeClass($a, 'cb-focus');
        });

        $a.addEventListener('focus', () => {
          Utils.addClass($a, 'cb-focus');
        });
      }
    });
  };

  const getFocusable = (node, next) => {
    let index = tabList.indexOf(node);

    if (next) {
      index++;
    } else {
      index--;
    }

    if (index < 0) {
      index = tabList.length > 0 ? tabList.length - 1 : 0;
    } else if (index === tabList.length) {
      index = 0;
    }

    tabList[index].focus();
  };

  const closeNavigationEvents = e => {
    const k = e.which || e.keyCode;

    if (k === 9 && !e.shiftKey) {
      //tab
      e.preventDefault();

      getFocusable($close, true);
    }
    if (k === 9 && e.shiftKey) {
      //shift & tab
      e.preventDefault();

      getFocusable($close, false);
    }
  };
  const nextNavigationEvents = e => {
    const k = e.which || e.keyCode;

    //  Enter or space
    if (k === 13 || k === 32) {
      e.preventDefault();

      $next.click();
    }

    if (k === 9 && !e.shiftKey) {
      //tab
      e.preventDefault();

      getFocusable($next, true);
    }
    if (k === 9 && e.shiftKey) {
      //shift & tab
      e.preventDefault();

      getFocusable($next, false);
    }
  };
  const desNavigationEvents = e => {
    const k = e.which || e.keyCode;

    if (k === 9 && !e.shiftKey) {
      //tab
      e.preventDefault();

      getFocusable($des, true);
    }

    if (k === 9 && e.shiftKey) {
      //shift & tab
      e.preventDefault();

      getFocusable($des, false);
    }
  };
  const prevNavigationEvents = e => {
    const k = e.which || e.keyCode;

    // 13: enter
    // 32: space
    if (k === 13 || k === 32) {
      e.preventDefault();

      $prev.click();
    }

    // 9: tab
    if (k === 9 && !e.shiftKey) {
      //tab
      e.preventDefault();

      getFocusable($prev, true);
    }

    if (k === 9 && e.shiftKey) {
      //shift & tab
      e.preventDefault();

      getFocusable($prev, false);
    }
  };
  const fullscreenNavigationEvents = e => {
    const k = e.which || e.keyCode;

    // 13: enter
    // 32: space
    if (k === 13 || k === 32) {
      e.preventDefault();

      $fullscreen.click();
    }

    // 9: tab
    if (k === 9 && !e.shiftKey) {
      //tab
      e.preventDefault();

      getFocusable($fullscreen, true);
    }

    if (k === 9 && e.shiftKey) {
      //shift & tab
      e.preventDefault();

      getFocusable($fullscreen, false);
    }
  };
  const playNavigationEvents = e => {
    const k = e.which || e.keyCode;

    // 13: enter
    // 32: space
    if (k === 13 || k === 32) {
      e.preventDefault();

      $play.click();
    }

    // 9: tab
    if (k === 9 && !e.shiftKey) {
      //tab
      e.preventDefault();

      getFocusable($play, true);
    }

    if (k === 9 && e.shiftKey) {
      //shift & tab
      e.preventDefault();

      getFocusable($play, false);
    }
  };

  const swipeNavigation = e => {
    if (e && e.data) {
      const swipeData = e.data;
      if (swipeData.offset.x !== 0) {
        if (swipeData.direction.x === 'left') {
          $prev.click();
        } else if (swipeData.direction.x === 'right') {
          $next.click();
        }
      }
    }
  };
  const playClickEvent = e => {
    let $nextPlay = null;

    if (e) {
      $nextPlay = e.currentTarget;

      stopAutoPlay();
    } else {
      $nextPlay = modal.querySelector('.cb-next-img');
      const time = parseInt(Utils.attr($nextPlay, 'data-cb-time'), 10);

      autoPlayTimeOut = setTimeout(playClickEvent, time);
    }
    const selected = Utils.attr($nextPlay, 'data-cb-item');

    data = plugin.items[selected];
    const next = getItemsNumber(0, selected);

    Utils.attr($nextPlay, 'data-cb-item', `${next}`);

    setGalleryData(data, selected);
  };

  const escFullScreen = () => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      Utils.removeClass(modal, 'cb-photo-full');
    } else {
      Utils.addClass(modal, 'cb-photo-full');
    }
  };

  const playBtnClickEvent = e => {
    if (e) e.preventDefault();

    const span = $play.querySelector('.cb-icon');

    if (!isPlaying) {
      Utils.addClass(span, 'cb-pause-circle');

      Utils.removeClass(span, 'cb-play-circle');

      isPlaying = true;

      playClickEvent();

      Utils.attr($play, 'aria-pressed', 'true');
    } else {
      stopAutoPlay();
    }
  };

  // keyboard navigation is clockwise
  const navigationEvents = () => {
    if (!singleImg) {
      const controller = modal.querySelector('.cb-gallery-controls');
      const btnCount = controller.querySelectorAll('.cb-btn').length;
      const counter = modal.querySelector('.cb-gallery-counter');
      const $hit = modal.querySelector('.cb-gallery-hit');

      if (counter) {
        Utils.addClass(counter, `cb-btn-${btnCount}`);
      }

      Utils.swipe($hit);

      $hit.addEventListener('swipe_end', swipeNavigation);
    }

    // close
    $close && $close.addEventListener('keydown', closeNavigationEvents);

    // arrows
    $next && $next.removeEventListener('click', playClickEvent);

    $next && $next.addEventListener('click', playClickEvent);

    // accessibility, enter/space=
    $next && $next.addEventListener('keydown', nextNavigationEvents);

    // Description accessibility
    $des && $des.addEventListener('keydown', desNavigationEvents);

    // Prev
    $prev && $prev.removeEventListener('click', prevClickEvent);

    $prev && $prev.addEventListener('click', prevClickEvent);

    // accessibility, enter/space
    $prev && $prev.addEventListener('keydown', prevNavigationEvents);

    // add events if fullscreen button exists
    if ($fullscreen) {
      Utils.addClass($img, 'cb-has-fullscreen');

      $fullscreen.removeEventListener('click', fullBtnClickEvent);

      $fullscreen.addEventListener('click', fullBtnClickEvent);

      if (document.addEventListener) {
        document.addEventListener('webkitfullscreenchange', escFullScreen, false);

        document.addEventListener('mozfullscreenchange', escFullScreen, false);

        document.addEventListener('fullscreenchange', escFullScreen, false);

        document.addEventListener('MSFullscreenChange', escFullScreen, false);
      }

      // accessibility, enter/space
      $fullscreen.addEventListener('keydown', fullscreenNavigationEvents);
    }

    // add events if play button exists
    if ($play) {
      Utils.attr($next, 'data-cb-time', plugin.autoPlayTime);

      $play.removeEventListener('click', playBtnClickEvent);

      $play.addEventListener('click', playBtnClickEvent);

      // accessibility, enter/space
      $play.addEventListener('keydown', playNavigationEvents);
    }
  };

  const setTabList = () => {
    tabList = [];

    if ($close) tabList.push($close);
    if ($next && !singleImg) tabList.push($next);
    if ($des && hasDesc) tabList.push($des);
    if ($prev && !singleImg) tabList.push($prev);
    if ($fullscreen) tabList.push($fullscreen);
    if ($play && !singleImg) tabList.push($play);
  };

  const destroy = () => {
    if (elem.photoGalleryPlugin === 'cb') {
      elem.photoGalleryPlugin = null;

      plugin = {};

      elem.querySelectorAll('.cb-photo-gallery > ul > li').forEach(li => {
        li.removeEventListener('click', e => thumbnailEventsClick(e, li));

        li.removeEventListener('keydown', e => thumbnailEventsKeyBoard(e, li));
      });

      window.removeEventListener('resize', gallerySizeAdjustment);

      document.removeEventListener('apricot_breakpointChange', breakpointChange);

      $close && $close.removeEventListener('keydown', closeNavigationEvents);

      $next && $next.removeEventListener('click', playClickEvent);

      $next && $next.removeEventListener('keydown', nextNavigationEvents);

      $des && $des.removeEventListener('keydown', desNavigationEvents);

      $prev && $prev.removeEventListener('click', prevClickEvent);

      $prev && $prev.removeEventListener('keydown', prevNavigationEvents);

      $play && $play.removeEventListener('click', playBtnClickEvent);

      $play && $play.removeEventListener('keydown', playNavigationEvents);

      if (!singleImg) {
        const $hit = modal.querySelector('.cb-gallery-hit');

        $hit && $hit.removeEventListener('swipe_end', swipeNavigation);
      }

      if ($fullscreen) {
        $fullscreen.removeEventListener('click', fullBtnClickEvent);

        $fullscreen.removeEventListener('keydown', fullscreenNavigationEvents);

        if (document.addEventListener) {
          document.removeEventListener('webkitfullscreenchange', escFullScreen, false);

          document.removeEventListener('mozfullscreenchange', escFullScreen, false);

          document.removeEventListener('fullscreenchange', escFullScreen, false);

          document.removeEventListener('MSFullscreenChange', escFullScreen, false);
        }
      }

      ModalIns && ModalIns.destroy();
    }
  };

  const init = () => {
    elem.photoGalleryPlugin = 'cb';

    Utils.attr(elem, 'id', elemId);

    Utils.attr(modal, 'id', modalId);

    plugin.autoPlayTime = data.autoPlayTime;

    $ul = elem.querySelectorAll('ul')[0];

    $close = modal.querySelector('.cb-btn-close');

    $play = modal.querySelector('.cb-btn-play');

    $fullscreen = modal.querySelector('.cb-btn-fullscreen');

    $next = modal.querySelector('.cb-next-img');

    $prev = modal.querySelector('.cb-prev-img');

    $des = modal.querySelector('.cb-gallery-des');

    $img = modal.querySelector('.cb-gallery-image-container');

    // first make sure the layout is correct
    if (!Utils.hasClass($ul, 'cb-photo-gallery-grid')) {
      thumbnailLayoutClass();
    } else {
      layoutType = 'cb-photo-gallery-grid';
    }

    if ($ul.querySelectorAll('.cb-photo-thumb-info').length === 0) {
      Utils.addClass(elem, 'cb-no-desc');
    }

    // retrieve data
    retrieveDataset();

    // start building the gallery
    setupGalleryModal();

    // add events
    thumbnailEvents();

    // modal events
    navigationEvents();

    // get focusable elements
    setTabList();

    // A11Y
    Utils.attr(modal.querySelector('.cb-gallery-counter[role="region"]'), 'aria-labelledby', `${imgId}`);

    Utils.attr($prev, 'aria-controls', imgId);

    Utils.attr($next, 'aria-controls', imgId);

    window.addEventListener('resize', gallerySizeAdjustment);

    Utils.breakpoints();

    viewport = Utils.viewport().prefix;

    document.addEventListener('apricot_breakpointChange', breakpointChange);
  };

  if (elem.photoGalleryPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default PhotoGallery;
