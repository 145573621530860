/* ========================================================================
 * Apricot's International Phone
 * ======================================================================== */

// SCSS
import '../scss/includes/forms-international-phone.scss';

// javaScript
import countriesList from '../api/CBCountries.json';
import Utils from './CBUtils';

// ------------------------------------  INTERNATIONAL PHONE INPUT
/**
 * International Phone Input
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Array} data.countries
 * @param {String} data.defaultCountry
 * @param {Number} data.defaultValue
 * @param {Number} data.maxLength
 * @param {Array} data.preferredCountries
 * @param {Boolean} data.XPMAll
 * @param {Boolean} data.usTerritories
 * @param {String} data.placeholder
 * @param {Function} data.onNumberChange
 * @param {Function} data.onCountryChange
 * @returns {{updateNumber: Function}}
 * @returns {{updateCountry: Function}}
 * @returns {{updateMaxLength: Function}}
 * @returns {{destroy: Function}}
 *
 */
const IntPhone = (data = {}) => {
  const defaultData = {
    elem: null,
    countries: null,
    defaultCountry: 'us',
    defaultValue: 0,
    maxLength: null,
    preferredCountries: ['us', 'ca'],
    XPMAll: false,
    usTerritories: true,
    placeholder: '(###) ###-####',
  };

  const elem = data.elem;
  if (!Utils.elemExists(elem)) return false;

  data = {
    ...defaultData,
    ...data,
  };

  let countries = [];
  let preferredCountries = data.preferredCountries;
  let defaultCountry = data.defaultCountry;
  let defaultValue = data.defaultValue;
  let placeholder = data.placeholder;

  let input = null;
  let ul = null;
  let dropdown = null;
  let toggle = null;
  let flag = null;
  let dialCodeSpan = null;
  let srOnly = null;
  let openIcon = null;
  let closeIcon = null;

  let filterCode = '';
  let filterType = '';
  let countryCode = '';
  let dialCode = 0;
  let disabled = false;
  let maxLength = null;

  const noTerritories = dataList => {
    return dataList.filter(obj => obj?.worldRegion !== '10' && obj?.worldRegion !== '9');
  };

  const setValue = (newValue, value, selectionStart, selectionEnd) => {
    if (dialCode > 0 && newValue !== '') {
      dialCodeSpan.innerHTML = `+${dialCode}`;
    }
    data.onNumberChange && data.onNumberChange(newValue);

    // calculate cursor position
    const diff = newValue.length - value.length;
    const adjustedCursorStart = selectionStart + diff;
    const adjustedCursorEnd = selectionEnd + diff;

    input.value = newValue;
    const newStart = Math.min(adjustedCursorStart, newValue.length);
    const newEnd = Math.min(adjustedCursorEnd, newValue.length);

    input.setSelectionRange(newStart, newEnd);

    // input.value = value;
  };

  const handlePlaceholderChange = value => {
    let newValue = '';
    let strippedValue = '';
    let isInt = false;
    let matchesNumber = false;
    value = value.toString();

    let num = '#';
    value = value.replace(`+${dialCode}`, '');

    // strip all characters
    strippedValue = value.replace(/\D/g, '');

    if (dialCode === '1') {
      for (let i = 0, j = 0; i <= placeholder.length; i++) {
        if (placeholder[i] === undefined) break;

        isInt = !isNaN(parseInt(strippedValue[j]));

        matchesNumber = num.indexOf(placeholder[i]) >= 0;

        if (matchesNumber && isInt) {
          newValue += strippedValue[j++];
        } else if (!isInt && matchesNumber) {
          return newValue;
        } else {
          newValue += placeholder[i];
        }
        if (strippedValue[j] === undefined) {
          break;
        }
      }
    } else {
      newValue = strippedValue;
    }

    return newValue;
  };

  const updateNumber = (num, selectionStart, selectionEnd) => {
    const numValue = handlePlaceholderChange(num);

    setValue(numValue, num, selectionStart, selectionEnd);
  };

  const processCountryData = () => {
    if (preferredCountries.length > 0) {
      let pcArr = [];
      preferredCountries.reverse();

      preferredCountries.forEach(iso => {
        if (countries.find(obj => obj.iso === iso)) {
          const isoIndex = countries.map(item => item.iso).indexOf(iso);

          pcArr.push(countries[isoIndex]);

          countries.splice(isoIndex, 1);
        }
      });

      pcArr.forEach(c => {
        countries.unshift(c);
      });
    }
  };

  const buildList = () => {
    countries.forEach(country => {
      const li = document.createElement('LI');

      Utils.addClass(li, 'cb-country');

      Utils.attr(li, 'role', 'presentation');

      const a = document.createElement('A');

      Utils.attr(a, 'data-cb-dial-code', country.dialCode);

      Utils.attr(a, 'data-cb-country-code', country.iso);

      Utils.attr(a, 'href', '#');

      Utils.attr(a, 'role', 'option');

      // const span1 = document.createElement("SPAN");
      // Utils.addClass(span1, "cb-flag");
      // Utils.addClass(span1, country.iso);
      // Utils.attr(span1, "aria-hidden", "true");
      // a.appendChild(span1);

      const span1 = document.createElement('SPAN');

      span1.innerHTML = `${country.countryName} (+${country.dialCode})`;

      a.appendChild(span1);

      li.appendChild(a);

      ul.appendChild(li);
    });
  };

  const updateMaxLength = max => {
    if (typeof max === 'number' && max > 0) {
      maxLength = max;
      if (countryCode !== 'us' && countryCode !== 'ca') {
        Utils.attr(input, 'maxlength', max);
      }
    } else {
      maxLength = null;
      if (countryCode !== 'us' && countryCode !== 'ca') {
        Utils.removeAttr(input, 'maxlength');
      }
    }
  };

  const getCountryData = iso => {
    const obj = countries.filter(country => country.iso === iso)[0];
    if (obj) {
      return obj;
    } else {
      return {};
    }
  };

  const getDialCodeData = dialCodeData => {
    const obj = countries.filter(country => country.dialCode === dialCodeData)[0];
    if (obj) {
      return obj;
    } else {
      return {};
    }
  };

  const selectListFlag = iso => {
    const item = ul.querySelector(`[data-cb-country-code="${iso}"]`);

    if (Utils.elemExists(item)) {
      Utils.removeClass(ul.querySelector('.cb-selected'), 'cb-selected');

      Utils.addClass(item, 'cb-selected');
    }
  };

  const adjustDropdownPosition = () => {
    const top = dropdown.getBoundingClientRect().top;
    const mHeight = dropdown.offsetHeight;
    const wHeight = Utils.windowsDimension().height;

    const limit = parseInt(wHeight - top);

    if (mHeight > limit) {
      Utils.addClass(dropdown, 'cb-revers');
    } else {
      Utils.removeClass(dropdown, 'cb-revers');
    }
  };

  const toggleDropdown = e => {
    if (e) e.preventDefault();

    // disabled
    if (disabled) return null;

    // is open -> close
    if (Utils.hasClass(dropdown, 'cb-open')) {
      Utils.removeClass(dropdown, 'cb-open');

      Utils.removeClass(dropdown, 'cb-revers');

      Utils.removeClass(openIcon, 'cb-hidden');

      Utils.addClass(closeIcon, 'cb-hidden');

      Utils.attr(toggle, 'aria-expanded', 'false');

      Utils.removeAttr(input, 'disabled');

      input.focus();
    } else {
      Utils.attr(input, 'disabled', 'true');

      Utils.addClass(dropdown, 'cb-open');

      adjustDropdownPosition();

      Utils.removeClass(closeIcon, 'cb-hidden');

      Utils.addClass(openIcon, 'cb-hidden');

      Utils.attr(toggle, 'aria-expanded', 'true');

      if (Utils.elemExists(ul.querySelector('.cb-selected'))) {
        ul.querySelector('.cb-selected').focus();
      } else {
        ul.querySelectorAll('.cb-country a')[0].focus();
      }
    }
  };

  const openDropdown = e => {
    e.preventDefault();

    e.stopPropagation();

    // space, down
    if (e.keyCode === 32 || e.keyCode === 40) {
      if (!Utils.hasClass(dropdown, 'cb-open')) {
        toggleDropdown(e);
      }
    }
  };

  const toggleFocusAnchor = mode => {
    if (disabled) return null;
    if (mode) {
      Utils.addClass(dropdown, 'cb-focus');
    } else {
      Utils.removeClass(dropdown, 'cb-focus');
    }
  };

  const closeA11Y = e => {
    if (e.type === 'click') {
      if (!Utils.hasClass(dropdown, 'cb-open') || dropdown.contains(e.target)) {
        return;
      }
      toggleDropdown();
    } else if (e.keyCode === 27) {
      if (!Utils.hasClass(dropdown, 'cb-open')) {
        return;
      }
      toggleDropdown();
    }
  };

  const toggleEvents = () => {
    toggle.addEventListener('click', toggleDropdown);

    toggle.addEventListener('keyup', openDropdown);

    toggle.addEventListener('focus', toggleFocusAnchor(true));

    toggle.addEventListener('blur', toggleFocusAnchor(false));
  };

  const closeOnClickOutside = () => {
    document.addEventListener('keydown', closeA11Y, true);

    document.addEventListener('click', closeA11Y, true);
  };

  const filterListFlag = (code, type) => {
    let newCode = '';
    let theFlag = null;
    code = code.toLowerCase();

    if (filterCode !== '') {
      if (type === 'country') {
        newCode = filterCode.length >= 2 ? code : filterCode + code;
      } else {
        newCode = ul.querySelectorAll(`[data-cb-${type}-code^="${filterCode + code}"]`) ? filterCode + code : code;
      }

      theFlag = ul.querySelectorAll(`[data-cb-${type}-code^="${newCode}"]`)[0];

      if (theFlag) {
        theFlag.focus();

        filterCode = newCode;
      } else {
        filterCode = '';
      }
    } else {
      theFlag = ul.querySelectorAll(`[data-cb-${type}-code^="${code}"]`)[0];
      if (theFlag) {
        theFlag.focus();

        filterCode = code;
      }
    }
  };

  const noFlag = () => {
    const value = input.value;

    //No country
    if (value === '' || value === '+') {
      if (Utils.attr(toggle, 'data-cb-country-code') !== '') {
        Utils.removeClass(flag, Utils.attr(toggle, 'data-cb-country-code'));

        Utils.removeAttr(toggle, 'data-cb-country-code');

        Utils.removeAttr(toggle, 'data-cb-dial-code');

        srOnly.innerHTML = 'Select country code';
      }
    }
  };

  const setPlaceholder = (code, value) => {
    if (code === 'us' || code === 'ca') {
      const mask = `${value}`;

      Utils.attr(input, 'placeholder', mask);

      Utils.attr(input, 'maxlength', mask.length);
    } else {
      Utils.removeAttr(input, 'placeholder');

      if (typeof maxLength === 'number') {
        Utils.attr(input, 'maxlength', maxLength);
      } else {
        Utils.removeAttr(input, 'maxlength');
      }
    }
  };

  const updateCountry = code => {
    let country = {};

    setValue('', '', 0, 0);

    Utils.removeAttr(flag, 'class');

    Utils.addClass(flag, 'cb-flag');

    if (!isNaN(code)) {
      country = getDialCodeData(code);
    } else if (code !== '') {
      country = getCountryData(code);
    }

    if (Utils.isEmptyObject(country)) {
      noFlag();
    } else {
      if (country.iso !== '') {
        countryCode = country.iso;

        dialCode = country.dialCode;

        Utils.addClass(flag, countryCode);

        Utils.attr(toggle, 'data-cb-country-code', countryCode);

        Utils.attr(toggle, 'data-cb-dial-code', dialCode);

        if (Utils.elemExists(dialCodeSpan)) {
          dialCodeSpan.innerHTML = `+${dialCode}`;
        }
        srOnly.innerHTML = `Country code for ${country.countryName} is selected`;

        selectListFlag(countryCode);

        // update placeholder
        setPlaceholder(countryCode, placeholder);

        //trigger custom event
        const event = new CustomEvent('apricot_intPhone');

        event.data = country;

        elem.dispatchEvent(event);

        const changeEvent = new Event('change');

        input.dispatchEvent(changeEvent);

        data.onCountryChange && data.onCountryChange(dialCode);
      }
    }

    filterCode = '';
  };

  const dropdownEvents = () => {
    ul.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();

        e.stopPropagation();

        // flag has change, reset input
        updateCountry(Utils.attr(link, 'data-cb-country-code'));

        toggleDropdown();
      });

      link.addEventListener('keydown', e => {
        const k = e.which || e.keyCode;

        if (k >= Utils.KEYS.ZERO && k <= Utils.KEYS.NINE) {
          filterCode = filterType === 'number' ? filterCode : '';

          filterType = 'number';

          filterListFlag(String.fromCharCode(e.which), 'dial');
        } else if (k >= Utils.KEYS.A && k <= Utils.KEYS.Z) {
          filterCode = filterType === 'string' ? filterCode : '';

          filterType = 'string';

          filterListFlag(String.fromCharCode(e.which), 'country');
        } else if (/(9|16)/.test(k)) {
          //tab/shift
          let index = 0;
          const tabbingBack = e.shiftKey;
          const items = ul.querySelectorAll('a');

          Array.prototype.forEach.call(items, function (item, i) {
            if (link === item) {
              index = i;
            }
          });

          if (k === 9 && tabbingBack && index === 0) {
            //make sure menus are closed after tab away

            toggleDropdown();
          } else if (!tabbingBack && index === items.length - 1) {
            e.preventDefault();

            const newActive = items.item(0);

            newActive.focus();
          } else {
            //up/down arrows
            if (k === 9 && tabbingBack) {
              index--; //up|shift+tab
            } else if (k === 9) {
              index++; //down|tab
            }

            if (index < 0 || index === items.length) {
              return;
            }
          }
        } else if (/(38|40)/.test(k)) {
          //up/down
          let index = 0;
          const items = ul.querySelectorAll('a');

          Array.prototype.forEach.call(items, function (item, i) {
            if (link === item) {
              index = i;
            }
          });

          if (k === 38) {
            index--; //up|shift+tab
          } else if (k === 40) {
            index++; //down|tab
          }

          if (index < 0 || index === items.length) {
            return;
          }

          const newActive = items.item(index);

          newActive.focus();
        }
      });

      link.addEventListener('keyup', e => {
        if (Utils.isKey(e, 'ESC')) {
          //esc
          e.preventDefault();

          toggleDropdown();
        }
      });
    });
  };

  const floatingLabelEvents = () => {
    const wrapper = Utils.getClosest(elem, '.cb-int-phone');
    if (!Utils.elemExists(wrapper)) return false;

    const isFloating = Utils.hasClass(wrapper, 'cb-floating-label');
    const label = wrapper.querySelector('label');

    if (isFloating) {
      // floatingLabel custom event
      input.addEventListener('apricot_inputBlur', () => {
        Utils.addClass(label, 'cb-focus-fl');

        setTimeout(() => {
          if (
            toggle === document.activeElement ||
            Utils.hasClass(dropdown, 'cb-focus') ||
            Utils.hasClass(dropdown, 'cb-open')
          ) {
            // keep it
            Utils.addClass(label, 'cb-focus-fl');
          } else {
            // remove it
            Utils.removeClass(label, 'cb-focus-fl');
          }
        }, 50);
      });
    }
  };

  const inputKeyDown = e => {
    // if (Utils.isKey(e, "PREV")) {
    //   //prev
    //   e.preventDefault();

    //   toggle.focus();
    // } else
    if (Utils.isKey(e, 'DOWN') || Utils.isKey(e, 'UP')) {
      //down/up
      e.preventDefault();

      toggleDropdown();
    }
  };

  const inputKeyUp = e => {
    const k = e.which || e.keyCode;
    const { value, selectionStart, selectionEnd } = e.target;

    let numValue = '';

    if (value !== '') {
      if ((k >= Utils.KEYS.SPACE && !e.metaKey) || k === Utils.KEYS.DEL) {
        // 32 is space, and after that it's all chars (not meta/nav keys)
        // del may change the number too
        numValue = handlePlaceholderChange(value);

        setValue(numValue, value, selectionStart, selectionEnd);
      }
    }
  };

  const inputEvents = () => {
    input.addEventListener('keydown', inputKeyDown);

    input.addEventListener('keyup', inputKeyUp);
  };

  const destroy = () => {
    if (elem.intPhonePlugin === 'cb') {
      elem.intPhonePlugin = null;

      toggle.removeEventListener('click', toggleDropdown);

      toggle.removeEventListener('keyup', openDropdown);

      toggle.removeEventListener('focus', toggleFocusAnchor(true));

      toggle.removeEventListener('blur', toggleFocusAnchor(false));

      input.removeEventListener('keydown', inputKeyDown);

      input.removeEventListener('keyup', inputKeyUp);

      document.removeEventListener('keydown', closeA11Y, true);

      document.removeEventListener('click', closeA11Y, true);

      ul.innerHTML = '';
    }
  };

  const init = () => {
    elem.intPhonePlugin = 'cb';

    ul = elem.querySelector('.cb-country-list');
    if (!Utils.elemExists(ul)) return false;

    dropdown = elem.querySelector('.cb-flag-dropdown');

    toggle = elem.querySelector('.cb-flag-selected');

    input = elem.querySelector('input');

    flag = toggle.querySelector('.cb-flag');

    srOnly = flag.querySelector('.sr-only');

    dialCodeSpan = flag.querySelector('.cb-dial-code');

    if (input.disabled === true) {
      disabled = true;

      Utils.addClass(input, 'cb-disabled');

      Utils.attr(toggle, 'disabled', 'true');

      Utils.attr(toggle, 'tabIndex', '-1');
    }

    // if we don't have a list, use default
    // for react component
    if (data.countries) {
      countries = data.countries;
    } else if (data.XPMAll === false) {
      countries = countriesList.countries.filter(c => c.country === true);
      if (!data.usTerritories) {
        countries = noTerritories(countries);
      }
    } else {
      countries = countriesList.countries;
      if (!data.usTerritories) {
        countries = noTerritories(countries);
      }
    }

    const icons = toggle.querySelectorAll('.cb-icon');
    if (icons[0]) {
      openIcon = icons[0];
      if (icons[1]) {
        closeIcon = icons[1];
      }
    }

    processCountryData();

    buildList();

    // Events
    toggleEvents();

    closeOnClickOutside();

    dropdownEvents();

    inputEvents();

    floatingLabelEvents();

    if (Utils.isBlank(defaultCountry)) {
      defaultCountry = 'us';
    }

    updateCountry(defaultCountry);

    if (countryCode !== 'us' && countryCode !== 'ca') {
      updateMaxLength(data.maxLength);
    }
    if (defaultValue && parseInt(defaultValue) > 0) {
      updateNumber(defaultValue, 0, 0);
    }

    input.addEventListener('animationstart', event => {
      // name is defined in the CSS animation - forms-international-phone.scss
      if (event.animationName === 'on-auto-fill-start-int-phone') {
        const { value } = event.target;

        updateNumber(value, 0, 0);
      }
    });
  };

  if (elem.intPhonePlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
    updateNumber: updateNumber,
    updateCountry: updateCountry,
    updateMaxLength: updateMaxLength,
  };
};

export default IntPhone;
