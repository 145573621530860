/* ========================================================================
 * Apricot's Cards
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/card.scss';
import '../scss/includes/text-with-media.scss';

// javaScript
export default {};
