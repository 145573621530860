/* ========================================================================
 * Apricot's Loader
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/loader.scss';

// javaScript
export default {};
