/* ========================================================================
 * Apricot's Back To Top
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/button.scss';
import '../scss/includes/back-to-top.scss';

// javaScript
import Utils from './CBUtils';
import Animation from './CBAnimation';

/**
 * Back To Top
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.topOfPageAnchor
 * @param {Number} data.offsetTop
 * @param {Boolean} data.animation
 * @param {Number} data.animationTime
 * @returns {{destroy: Function}}
 */
const BackToTop = (data = {}) => {
  const defaultData = {
    elem: null,
    topOfPageAnchor: null,
    offsetTop: 72,
    animation: true,
    animationTime: 500,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let topOfPageAnchor = data.topOfPageAnchor;
  let offsetTop = data.offsetTop;
  const winElem = window;

  if (!Utils.elemExists(elem)) return null;

  const scrollFunction = () => {
    if (document.body.scrollTop > offsetTop || document.documentElement.scrollTop > offsetTop) {
      Utils.removeClass(elem, 'cb-back-to-top-hide');

      Utils.addClass(elem, 'cb-back-to-top-show');

      Utils.attr(elem, 'aria-hidden', 'false');

      Utils.attr(elem, 'tabIndex', '0');
    } else {
      Utils.removeClass(elem, 'cb-back-to-top-show');

      Utils.addClass(elem, 'cb-back-to-top-hide');

      Utils.attr(elem, 'aria-hidden', 'true');

      Utils.attr(elem, 'tabIndex', '-1');
    }
  };

  const setFocus = () => {
    if (Utils.elemExists(topOfPageAnchor)) {
      topOfPageAnchor.focus();
    } else {
      console.warn('Apricot Back to Top: Seems like you have missed topOfPageAnchor');

      return false;
    }
  };

  const scrollUp = e => {
    e.preventDefault();

    let ms = data.animation ? data.animationTime : 0;
    let topOffset = Utils.offset(elem).top;

    ms = (topOffset * ms) / 800;

    // this is not be supported in all browsers
    if (Utils.reduceMotionChanged()) ms = 0;

    Animation.pageScrollAnimate(0, ms, 'linear', setFocus);
  };

  const destroy = () => {
    if (elem.backToTopPlugin === 'cb') {
      elem.backToTopPlugin = null;

      winElem.removeEventListener('scroll', scrollFunction);

      elem.removeEventListener('click', scrollUp);
    }
  };

  const init = () => {
    elem.backToTopPlugin = 'cb';

    winElem.addEventListener('scroll', scrollFunction);

    elem.addEventListener('click', scrollUp);
  };

  if (elem.backToTopPlugin !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default BackToTop;
