/* ========================================================================
 * Apricot's Menu-List
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/menu-list.scss';
import '../scss/includes/menu-bar.scss';

// javaScript
import CBAccordion from './CBAccordion';
import Utils from './CBUtils';

/**
 * Menu list Accordion
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Number} data.visibleItems
 * @param {String} data.ariaLabel
 * @param {String} data.triggerAlignment
 * @param {String} data.triggerLabel
 * @param {String} data.triggerLabelActive
 * @param {Boolean} data.animation
 * @param {Function} data.callBack
 * @returns {{destroy: Function}}
 */
const MenuListAccordion = (data = {}) => {
  const defaultData = {
    elem: null,

    visibleItems: 5,
    ariaLabel: 'more items',
    triggerAlignment: 'left',
    triggerLabel: 'See More',
    triggerLabelActive: 'See Less',

    animation: true,
    callBack: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let list = null;

  if (!Utils.elemExists(elem)) return null;

  const adjustCallback = node => {
    if (node) {
      if (Utils.hasClass(node, 'cb-in')) {
        const items = node.querySelectorAll(Utils.FOCUSABLE_ELEMENTS);
        if (items.length > 0) {
          items[0].focus();
        }
      }
    }

    if (data.callBack) {
      data.callBack(node);
    }
  };

  const buildAccordion = () => {
    const div = document.createElement('DIV');

    Utils.addClass(div, ['cb-accordion-trigger-panel', 'transition']);

    Utils.attr(div, 'role', 'region');

    Utils.attr(div, 'aria-label', data.ariaLabel);

    const a = document.createElement('A');

    Utils.addClass(a, ['cb-link-blue', 'cb-accordion-trigger', `cb-align-${data.triggerAlignment}`]);

    Utils.attr(a, 'href', '#');

    const span = document.createElement('SPAN');

    span.innerText = data.triggerLabel;

    a.appendChild(span);

    list.appendChild(div);

    list.appendChild(a);

    // move items
    list.querySelectorAll('[role="listitem"').forEach((item, index) => {
      if (index >= data.visibleItems) {
        div.appendChild(item);
      }
    });

    if (div.querySelectorAll('[role="listitem"]').length > 0) {
      CBAccordion({
        elem: elem.querySelector('.cb-accordion-trigger'),
        targetElem: elem.querySelector('.cb-accordion-trigger-panel'),
        triggerLabel: data.triggerLabel,
        triggerLabelActive: data.triggerLabelActive,
        animation: data.animation,
        callBack: adjustCallback,
      });
    }
  };

  const destroy = () => {
    if (elem.menuListAccordion === 'cb') {
      elem.menuListAccordion = null;
    }

    elem.querySelector('.cb-accordion-trigger').remove();

    // move items
    elem
      .querySelector('.cb-accordion-trigger-panel')
      .querySelectorAll('[role="listitem"')
      .forEach(item => {
        list.appendChild(item);
      });

    elem.querySelector('.cb-accordion-trigger-panel').remove();
  };

  const init = () => {
    elem.menuListAccordion = 'cb';

    list = elem.querySelector('[role="list"]');

    if (!Utils.elemExists(list)) return null;

    buildAccordion();
  };

  if (elem.menuListAccordion !== 'cb') {
    init();
  }

  return {
    destroy: destroy,
  };
};

export default MenuListAccordion;
