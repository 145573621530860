/* ========================================================================
 * Apricot's Print
 * ======================================================================== */

// SCSS
import '../scss/includes/apricot-base.scss';
import '../scss/includes/print.scss';

// javaScript
export default {};
